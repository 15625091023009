<template>
  <div>
    <div class="radioButtonFacet desktop" v-if="!isHidden">
      <label class="facetTitle">
        {{ header }}
      </label>
      <div>
        <div :class="['facetItem', { selected: selectedFacets.includes(facet.value) }]"
             v-for="(facet, index) in facets"
             :key="index">
          <div class="choiceFacet">
            <input class="facetItemCheckbox" type="checkbox" :checked="selectedFacets.includes(facet.value)" @click="toggleFacet(facet)">
            <span @click="toggleFacet(facet)" v-if="facetProperty === 'PromotionTag'">
              {{ useI18nResources ? $t(`${i18nNamespace}.${facet.value}`) : getFacetText($t(`product.promotionTags.${facet.value}`)) }}
              <span v-if="showCount && facet.count">({{ facet.count }})</span>
            </span>
            <span @click="toggleFacet(facet)" v-else>
              {{ useI18nResources ? $t(`${i18nNamespace}.${facet.value}`) : getFacetText(facet.value) }}
              <span v-if="showCount && facet.count">({{ facet.count }})</span>
            </span>
            
          </div>
        </div>
        <div v-if="allText" :class="['facetItem', 'all', { selected: selectedFacets.length === 0 }]">
          <span @click="resetFacets">{{ allText }}</span>
        </div>
      </div>
    </div>
    <div class="dropdownFacet mobile" v-if="!isHidden">
      <label class="facetTitle">{{ header }}</label>
      <div>
        <div class="activator" @click="isDropdownOpen = !isDropdownOpen">
          <span>{{ dropdownValues }}</span>
        </div>
        <div class="facets" v-show="isDropdownOpen">
          <div v-if="allText" @click="resetFacets" :class="['facetItem', 'all', { selected: selectedFacets.length === 0 }]">
            <span>{{ allText }}</span>
          </div>
          <div :class="['facetItem', { selected: selectedFacets.includes(facet.value) }]"
              v-for="(facet, index) in facets"
              @click="toggleFacet(facet)"
              :key="index">
            <checkbox
              v-model="selectedFacets"
              :checkedValue="facet.value"
              @input="refreshSelectedFacets"
            />
            <span @click="toggleFacet(facet)" v-if="facetProperty === 'PromotionTag'">
              {{ useI18nResources ? $t(`${i18nNamespace}.${facet.value}`) : getFacetText($t(`product.promotionTags.${facet.value}`)) }}
              <span v-if="showCount && facet.count">({{ facet.count }})</span>
            </span>
            <span @click="toggleFacet(facet)" v-else>
              {{ useI18nResources ? $t(`${i18nNamespace}.${facet.Value}`) : getFacetText(facet.value) }}
              <span v-if="showCount && facet.count">({{ facet.count }})</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';
import hashMixins from '@/hashMixins';

export default {
  components: {
    Checkbox
  },
  props: {
    hideIfFilterCountLessThan: {
      type: [Boolean,Number],
      default: false
    },
    itemsContainer: {
      type: Object,
      required: true
    },
    facetProperty: {
      type: String,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    showCount: {
      type: Boolean,
      default: false
    },
    allText: {
      type: String,
      default: null
    },
    emptyText: {
      type: String,
      default: null
    },
    requestFacetProperty: {
      type: String,
      required: true
    },
    i18nNamespace: {
      type: String,
      default: null
    },
    useI18nResources: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      facets: [],
      isDropdownOpen: false,
      selectedDropdownFacet: [],
      selectedFacets: [],
      hashParams: null
    };
  },
  created() {
    this.hashParams = this.getHashParams();
    if (this.facetProperty === 'MinorCategory') {
      this.itemsContainer.lastSelectedFiltrationFacetName = this.facetProperty;
    }
  },
  computed: {
    dropdownValues() {
      const promotionTagFacetProperty = this.facetProperty === 'PromotionTag';
      const facets = this.useI18nResources
        ? this.selectedFacets.map(facet => this.$t(`${this.i18nNamespace}.${facet}`))
        : promotionTagFacetProperty ? this.selectedFacets.map(facet => this.$t(`product.promotionTags.${facet}`)) : this.selectedFacets;

      if (!facets.length) {
        return this.allText ? this.allText : this.emptyText;
      } else {
        return facets.join(', ');
      }
    },
    isHidden() {
      return Number.isInteger(this.hideIfFilterCountLessThan)
        ? this.facets.length < this.hideIfFilterCountLessThan && !this.selectedFacets.length
        : !!this.hideIfFilterCountLessThan;
    }
  },
  methods: {
    getPropertyFacets() {
      this.facets = this.itemsContainer.filtrationFacets
        .filter(x => x.name === this.facetProperty);
      if (this.hashParams[this.requestFacetProperty] != null) {
        this.selectedFacets = this.getListFromParam(this.hashParams[this.requestFacetProperty]);
      }   
      this.refreshSelectedFacets();
    },
    getFacetText(facetText) {
      if (!facetText) {
        return this.$i18n.t('facets.others');
      }

      return facetText;
    },
    toggleFacet(facet) {
      if (this.selectedFacets.indexOf(facet.value) === -1) {
        this.selectedFacets = [ ...this.selectedFacets, facet.value ];
      } else {
        this.selectedFacets = this.selectedFacets.filter(x => x !== facet.value);
      }
      this.itemsContainer.lastSelectedFiltrationFacetName = facet.name;
      this.$root.$emit('resetPage');
      this.refreshSelectedFacets();
    },
    refreshSelectedFacets() {
      this.itemsContainer.selectedFacets = {
        ...this.itemsContainer.selectedFacets,
        [this.requestFacetProperty]: this.selectedFacets
      };
    },
    resetFacets() {
      this.selectedFacets = [];
      this.refreshSelectedFacets();

      this.isDropdownOpen = false;
    },
    setFacetsCount() {
      if (this.itemsContainer.lastSelectedFiltrationFacetName == null || this.itemsContainer.lastSelectedFiltrationFacetName !== this.facetProperty) {
        for (const facet of this.facets) {
          var containerFacet = this.itemsContainer.facets.find(x => x.value === facet.value);
          facet.count = containerFacet != null ? containerFacet.count : '0';
        }
      }
    }
  },
  watch: {
    async 'itemsContainer.filtrationFacets'() {
      this.getPropertyFacets();
    },
    async 'itemsContainer.selectedFacets'() {
      this.setFacetsCount();
    }
  },
  mixins: [hashMixins]
};
</script>

<style lang="scss" scoped>
</style>
