var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "productResult relatedProduct",
      class: { cartPageRelatedProduct: _vm.isForCartPage },
    },
    [
      _c("div", { staticClass: "productInfosImg" }, [
        _vm.isForCartPage
          ? _c("div", { staticClass: "header" }, [
              _vm.product.price &&
              _vm.product.price.tag &&
              !_vm.product.isPriceInStoreOnly
                ? _c(
                    "div",
                    { class: ["sectionPromo", "icon" + _vm.product.price.tag] },
                    [
                      _c("span", { staticClass: "discountValue" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$root.getDiscount(
                                _vm.product.price.discountValue
                              )
                            )
                          ),
                          _c("sup", [
                            _vm._v(
                              _vm._s(
                                _vm.product.price.discountType === 1 ? "$" : "%"
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.product.providerRegion === 2
                ? _c("div", { staticClass: "provider" }, [
                    _c("img", {
                      attrs: {
                        src: "/images/provider-ca.png",
                        alt: "Fournisseur canadien",
                      },
                    }),
                  ])
                : _vm.product.providerRegion === 1
                ? _c("div", { staticClass: "provider" }, [
                    _c("img", {
                      attrs: {
                        src: "/images/provider-qc.png",
                        alt: "Fournisseur québécois",
                      },
                    }),
                  ])
                : _vm.product.madeInRegion === 2
                ? _c("div", { staticClass: "provider" }, [
                    _c("img", {
                      attrs: {
                        src: "/images/made-in-canada.png",
                        alt: "Fabriqué au Canada",
                      },
                    }),
                  ])
                : _vm.product.madeInRegion === 1
                ? _c("div", { staticClass: "provider" }, [
                    _c("img", {
                      attrs: {
                        src: "/images/made-in-quebec.png",
                        alt: "Fabriqué au Québec",
                      },
                    }),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _c("div", { staticClass: "img" }, [
          _c("a", { attrs: { href: _vm.product.url } }, [
            _vm.product.images && _vm.product.images.length
              ? _c("img", {
                  attrs: {
                    onerror: "this.src='/images/image-default.png';",
                    src:
                      _vm.product.images[0].url != null
                        ? _vm.product.images[0].url
                        : "/images/image-default.png",
                    alt: _vm.product.images[0].alt,
                    loading: "lazy",
                  },
                })
              : _c("img", {
                  attrs: {
                    src: "/images/image-default.png",
                    alt: "Image par d�faut",
                    loading: "lazy",
                  },
                }),
          ]),
        ]),
        _c("div", { staticClass: "productInfos" }, [
          !_vm.isForCartPage &&
          _vm.product.isWebExclusive != null &&
          _vm.product.isWebExclusive == true
            ? _c("div", { staticClass: "pill" }, [
                _vm._v(" " + _vm._s(_vm.$t("product.pill.webOnly")) + " "),
              ])
            : _vm._e(),
          _c("div", { staticClass: "name" }, [
            _c("a", { attrs: { href: _vm.url } }, [
              _vm._v(_vm._s(_vm.product.name)),
            ]),
          ]),
          _vm.isForCartPage
            ? _c("div", { staticClass: "sku" }, [
                _vm._v("#" + _vm._s(_vm.product.sku)),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "unityPrice" },
        [
          !this.$root.getFeatureFlag("hidePrices")
            ? [
                _vm.product.price != null &&
                _vm.product.price.promoSquareFootPrice != null &&
                _vm.product.price.squareFootPrice != null
                  ? _c("div", { staticClass: "price discounted" }, [
                      _c("div", { staticClass: "wDiscount" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$root.formatPrice(
                              _vm.product.price.promoSquareFootPrice
                            )
                          ) + _vm._s(_vm.$t("product.squareFootPriceUnit"))
                        ),
                      ]),
                      _c("div", { staticClass: "original" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$root.formatPrice(
                              _vm.product.price.squareFootPrice
                            )
                          ) + _vm._s(_vm.$t("product.squareFootPriceUnit"))
                        ),
                      ]),
                    ])
                  : _vm.product.price != null &&
                    _vm.product.price.squareFootPrice != null
                  ? _c("div", { staticClass: "price regular" }, [
                      _c("span", { staticClass: "original" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$root.formatPrice(
                              _vm.product.price.squareFootPrice
                            )
                          ) + _vm._s(_vm.$t("product.squareFootPriceUnit"))
                        ),
                      ]),
                    ])
                  : _vm.product.price != null && _vm.product.price.discounted
                  ? _c("div", { staticClass: "price discounted" }, [
                      _c("div", { staticClass: "wDiscount" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$root.formatPrice(_vm.product.price.discounted)
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "original" }, [
                        _vm._v(
                          _vm._s(_vm.$root.formatPrice(_vm.product.price.value))
                        ),
                      ]),
                    ])
                  : _vm.product.price != null
                  ? _c("div", { staticClass: "price regular" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$root.formatPrice(_vm.product.price.value))
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "price regular" }, [
                      _c("span", [_vm._v("N/D")]),
                    ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.isForCartPage &&
      _vm.product.isWebExclusive != null &&
      _vm.product.isWebExclusive == true
        ? _c("div", { staticClass: "pill" }, [
            _vm._v(" " + _vm._s(_vm.$t("product.pill.webOnly")) + " "),
          ])
        : _vm._e(),
      _c("add-to-cart-indicator", { attrs: { product: _vm.product } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }