import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import cartModule from './cartModule';
import globalModule from './globalModule';
import productModule from './productModule';
import searchModule from './searchModule';
import { addShippingExtra } from './cartModule/getters';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    cartModule: {
      account: state.cartModule.account,
      activePromoCode: state.cartModule.activePromoCode,
      addShippingExtra: state.cartModule.addShippingExtra, 
      billingAddress: state.cartModule.billingAddress,
      cart: state.cartModule.cart,
      customer: state.cartModule.customer,
      orderStep: state.cartModule.orderStep,
      paymentMode: state.cartModule.paymentMode,
      pickupContact: state.cartModule.pickupContact,
      pickupMyself: state.cartModule.pickupMyself,
      pickupOptions: state.cartModule.pickupOptions,
      relatedProducts: state.cartModule.relatedProducts,
      shippingAddress: state.cartModule.shippingAddress,
      shippingMethod: state.cartModule.shippingMethod,
      shippingPrice: state.cartModule.shippingPrice,
      useBillingAddress: state.cartModule.useBillingAddress
    },
    globalModule: {
      hasPromoCodePopupBeenSeen: state.globalModule.hasPromoCodePopupBeenSeen,
      selectedStoreInformation: state.globalModule.selectedStoreInformation
    }
  })
});

export default new Vuex.Store({
  namespaced: true,
  modules: {
    cartModule,
    globalModule,
    productModule,
    searchModule
  },
  plugins: [vuexLocal.plugin]
});
