import shippingMethods from '@/models/store/ShippingMethod';

const state = () => ({
  acceptedTermsConditions: false,
  addShippingExtra: false,
  account: {},
  activePromoCode: null,
  billingAddress: null,
  businessAddress: '',
  businessHours: '',
  callCustomer: false,
  captchaToken: '',
  cart: {},
  customer: {},
  deliveryDate: '',
  deliveryDates: [],
  globalPaymentInfo: {},
  isProcessingOrder: false,
  orderStep: 0,
  paymentMode: 0,
  pickupContact: {},
  pickupMyself: true,
  pickupOptions: {},
  products: [],
  shippingError: false,
  shippingAddress: {},
  shippingMethod: shippingMethods.Pickup,
  shippingExtraPrice: 0,
  shippingPrice: 0,
  subTotal: 0,
  useBillingAddress: undefined
});

export default state;
