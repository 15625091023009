<template>
  <modal
    v-if="!this.hasPromoCodePopupBeenSeen"
    @onclose="close()"
    :isPopin="true"
    :click-outside-enabled="false"
  >
    <template v-slot:body>
      <slot></slot>
      
    </template>
  </modal>
</template>

<script>
import Modal from '../UI/Modal.vue';
import { mapActions, mapState } from 'vuex'

export default {
  props: {
  },
  components: {
    Modal
  },
  data() {
    return {
    };
  },
  mounted() {
    const mailchimpButton = document.getElementById('mc-embedded-subscribe');
      
    if (mailchimpButton) {
      mailchimpButton.addEventListener('click', () => { setTimeout(this.close, 1000)});
    }

    const popupContainer = document.getElementById('marketing-popup-container');

    popupContainer.classList.add('isLoaded');
  },
  methods: {
    ...mapActions('globalModule', ['setHasPromoCodePopupBeenSeen']),
    close() {
      this.setHasPromoCodePopupBeenSeen(true);
      this.$emit('close');
    },
  },
  computed: {
    ...mapState('globalModule', ['hasPromoCodePopupBeenSeen']),
  }
};
</script>