var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isLoading
      ? _c("div", { staticClass: "loadingProduct" }, [
          _c("img", { attrs: { src: "/images/triangle-loading.gif" } }),
        ])
      : _c(
          "div",
          { staticClass: "carousels" },
          _vm._l(_vm.carouselsItems, function (carousel, index) {
            return _c("div", [
              _c(
                "div",
                {
                  staticClass: "carouselSection",
                  class: index % 2 != 0 ? "inverted" : "",
                },
                [
                  _c("div", { staticClass: "overlay" }),
                  carousel.image != null
                    ? _c("img", {
                        staticClass: "carouselSectionImage",
                        attrs: { src: carousel.image },
                      })
                    : _vm._e(),
                  _c("landing-page-carousel", {
                    attrs: {
                      carousel: carousel,
                      products: _vm.products,
                      "show-regions": _vm.showRegions,
                    },
                  }),
                ],
                1
              ),
            ])
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }