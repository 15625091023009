<template>
  <div class="stockInfosSection">
    <div class="stores">
      <div class="store">{{ storeName }}</div>
    </div>
    <div class="stock valid" v-if="isBarcodeItem(productData) || isWebExclusive(productData)">
    {{ $t('product.availability.inStock') }}
    </div>
    <div class="stock valid" v-else-if="quantity && quantity.value > 0">
    {{ $t('product.availability.inStockWithValue', { value: quantity.value }) }}
    </div>
    <div class="stock outStock" v-else>{{ $t('product.availability.notInStock') }}</div>
    <div v-if="$root.getStore() !== storeNumber" class="changeStore">
      <a v-on:click="$root.onChangeStore(storeNumber)">{{ $t('chooseThisStore') }}</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import productMixins from '@/productMixins';
import StoreChangeWarningModal from '../UI/StoreChangeWarningModal.vue';
import stores from '@/models/store/Store';

export default {
  components: { 
    StoreChangeWarningModal 
  },
  data() {
    return {
      showWarningModal: false,
      stores: stores
    };
  },
  props: {
    productData: {
      type: Object
    },
    store: {
      type: String
    },
    storeNumber: {
      type: Number
    }
  },
  methods: {
    getObjectByProperty(product, listProperty) {
      return product && product[listProperty]
        ? product[listProperty].find(x => x.store === this.storeNumber)
        : null;
    }
  },
  computed: {
    ...mapGetters('cartModule', ['count']),
    quantity() {
      return this.getObjectByProperty(this.productData, 'quantities');
    },
    storeName() {
      const storeCode = Object.keys(this.stores).find(key => 
        this.stores[key] === this.stores[this.store]);

      return !storeCode ? '' : this.$t(`stores.${storeCode}`);
    }
  },
  mixins: [productMixins]
};
</script>
