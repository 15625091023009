<template>
  <div>
    <div v-if="isLoading" class="loadingProduct">
      <img src="/images/triangle-loading.gif" />
    </div>
    <div v-else class="carousels">
      <div v-for="(carousel, index) in carouselsItems">
        <div class="carouselSection" :class="index % 2 != 0 ? 'inverted' : ''">
          <div class="overlay"></div>
          <img v-if="carousel.image != null" :src="carousel.image" class="carouselSectionImage" />          
          <landing-page-carousel :carousel="carousel" :products="products" :show-regions="showRegions"></landing-page-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LandingPageCarousel from '@/components/Marketing/LandingPageCarousel.vue';
export default {
  data() {
    return {
      isLoading: true,
      products: []
    }
  },
  components: {
    LandingPageCarousel
  },
  props: {
    carouselsItems: {
      type: Array,
      required: true
    },
    majorCategoryOmniCodes: {
      type: String,
      required: true
    },
    showRegions: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    const response = await this.$axios.get(`/Umbraco/Api/Products/GetAllFromMajorCategory?categoryOmniCodes=${this.majorCategoryOmniCodes}&categoryType=3&store=${this.$root.getStore()}`, { // 3 is for major category
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
          }
        });
    this.products = response.data;
    this.isLoading = false;
    
  },
  updated() {
    var url = decodeURI(location.href);
    var anchor = url.substring(url.lastIndexOf('#') + 1).split(' ').join('').normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace('\'', '').replace(',', '').toLowerCase();
    var element = document.getElementById(anchor);
    if (anchor != '' && element != null) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>