var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !this.hasPromoCodePopupBeenSeen
    ? _c("modal", {
        attrs: { isPopin: true, "click-outside-enabled": false },
        on: {
          onclose: function ($event) {
            return _vm.close()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function () {
                return [_vm._t("default")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }