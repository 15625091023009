<template>
  <div>
    <div v-if="isLoading" class="loading">
      <img src="/images/triangle-loading.gif" />
    </div>
    <div v-else class="order">
      <div v-if="!isLoggedIn" class="accountIncitator">
        <p v-html="$t('users.messages.notLoggedIn')"></p>
      </div>
      <back-to-cart :href="cartUrl" />
      <div class="left">
        <error-modal />
        <h1>{{ $t('order.title') }}</h1>
        <validation-observer v-slot="{ validate }" slim>
          <order-customer
            :addressCompleteSettings="addressCompleteSettings"
            :promo-code-configuration="promoCodeConfiguration"
            :shipping-disabled="shippingDisabled"
            :user="user"
          >
            <template v-slot:legalnotices>
              <slot name="legalnotices" />
            </template>
          </order-customer>
          <order-payment
            :addressCompleteSettings="addressCompleteSettings"
            :bambora-configuration="bamboraConfiguration"
            :confirmationUrl="confirmationUrl"            
            :recaptchaSiteKey="recaptchaSiteKey"
            :validate="validate"
            :user="user"
            :user-payment-is-enabled="userPaymentIsEnabled"
            @updateUserPayment="handleUserPaymentUpdate"
            @deletePayment="handleDeletePayment"
          />
        </validation-observer>
      </div>
      <div class="right">
        <order-details
          :gst="isTpsExemptionEnabled ? applyGst() : gst"
          :has-regular-and-barcode-items="hasRegularAndBarcodeItems(products)"
          :promo-code-configuration="promoCodeConfiguration"
          :qst="qst"
          :shipping="shippingMethod === 0 ? shippingPrice : null"
          :shippingExtra="addShippingExtra === true ? shippingExtraFee : null"
          :sub-total="addShippingExtra === true ? subTotal(parseInt(shippingExtraFee)) : subTotal()"
          :total="total()">
          <template v-slot:legalnotices>
            <slot name="legalnotices" />
          </template>
        </order-details>
      </div>
    </div>
  </div>
</template>

<script>
import BackToCart from './BackToCart.vue';
import ErrorModal from '@/components/UI/ErrorModal.vue';
import OrderCustomer from './OrderCustomer.vue';
import OrderDetails from '@/components/Cart/OrderDetails';
import orderMixins from '@/orderMixins';
import OrderPayment from './OrderPayment.vue';
import OrderProducts from './OrderProducts.vue';
import OrderStep from '@/models/cart/OrderStep';
import { mapActions, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';

export default {
  components: {
    BackToCart,
    ErrorModal,
    OrderCustomer,
    OrderDetails,
    OrderPayment,
    OrderProducts,
    ValidationObserver
  },
  props: {
    addressCompleteSettings: {
      type: Object,
      required: true
    },
    bamboraConfiguration: {
      type: Object,
      required: true
    },
    cartUrl: {
      type: String,
      required: true
    },
    confirmationUrl: {
      type: String,
      required: true
    },
    isTpsExemptionEnabled: {
      type: Boolean,
      default: false
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    memberId: {
      type: Number,
      default: null
    },
    tpsExemptedProductsConfiguration: {
      type: Object,
      default: null
    },
    promoCodeConfiguration: {
      type: Object,
      default: null,
    },
    recaptchaSiteKey: {
      type: String,
      required: true
    },
    shippingExtraFee: {
      type: String,
      required: true
    },
    userPaymentIsEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: true,
      isLoadingUser: false,
      orderSteps: OrderStep,
      user: null,
      hasProductsBeenRemovedFromCart: false
    };
  },
  async mounted() {
    await this.getUser()
    const productIds = Object.keys(this.cart);
    await this.$store.dispatch('cartModule/setProducts', productIds);
    for (const product of productIds) {
      if (!this.products.some(x => x.identifier.includes(product))) {
        this.deleteProduct(product);
        this.hasProductsBeenRemovedFromCart = true;
      }
    }
    if (this.hasProductsBeenRemovedFromCart === true) {
      this.$tostini({ message: this.$t('product.availability.removedFromCart'), duration: 5000 });
      this.hasProductsBeenRemovedFromCart = false;
    }
    this.isLoading = false;
  },
  computed: {
    ...mapState('cartModule', ['addShippingExtra', 'cart', 'isProcessingOrder', 'orderStep', 'products', 'shippingMethod', 'shippingExtraPrice', 'shippingPrice']),
    shippingDisabled() {
      return Boolean(
        this.products.find((x) => x.dangerousProduct || x.pesticide)
      );
    }
  },
  methods: {
    ...mapActions('cartModule', ['setOrderStep','removeProduct', 'setShippingExtraPrice']),
    deleteProduct(productId) {
      this.removeProduct(productId);
    },
    sendBeginCheckoutGaEvent() {
      try {
        if (dataLayer) {
          dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          dataLayer.push({
            event: 'begin_checkout',
            ecommerce: {
              items: this.products.map(item => ({
                item_name: item.name,
                item_id: item.sku,
                item_category: item.majorCategory,
                item_category2: item.intermediateCategory,
                item_category3: item.minorCategory
              }))
            }
          });
        }
      } catch { /*Fait rien*/ }
    },
    async getUser() {
      if (!this.memberId) {
        return null
      }

      try {
        this.isLoadingUser = true

        const response = await this.$axios.get(`/Umbraco/Api/User/GetUserProfile?memberId=${this.memberId}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })

        if (response.status === 200) {
          this.user = response.data
        }
        
      } catch(e) {
        // Fait rien
      } finally {
        this.isLoadingUser = false
      }
    },
    handleUserPaymentUpdate(payment) {
      this.user.payment = payment
    },
    handleDeletePayment() {
      this.user.payment = null
    }
  },
  created() {
    this.setShippingExtraPrice(parseInt(this.shippingExtraFee));
    if (!this.orderStep) {
      this.setOrderStep(OrderStep.Customer);
    }
  },
  watch: {
    products() {
      if (this.products) {
        this.sendBeginCheckoutGaEvent();
      }
    }
  },
  mixins: [orderMixins]
};
</script>
