var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "productsExplorer" }, [
    _c(
      "div",
      { staticClass: "left productsNavigation", attrs: { id: "filter" } },
      [
        _c(
          "div",
          { staticClass: "wrapper" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("searchResults.filter"))),
            ]),
            _c("a", { staticClass: "close", attrs: { id: "closeFilter" } }),
            _c(
              "div",
              { staticClass: "sorting" },
              [
                _c("dropdown-count", {
                  attrs: {
                    "items-container": _vm.itemsContainer,
                    header: _vm.$t("searchResults.productsByPage"),
                    values: [32, 64],
                  },
                  on: {
                    "update:itemsContainer": function ($event) {
                      _vm.itemsContainer = $event
                    },
                    "update:items-container": function ($event) {
                      _vm.itemsContainer = $event
                    },
                  },
                }),
                _c("dropdown-sort", {
                  attrs: {
                    "items-container": _vm.itemsContainer,
                    header: _vm.$t("searchResults.filterBy"),
                    "ascending-text": "ascendant",
                    "descending-text": "descendant",
                    values: {
                      1: { name: "Nom", order: 2 },
                      2: { name: "Prix", requireSelectedStore: true, order: 3 },
                      3: { name: "Date", requireSelectedStore: true, order: 4 },
                      5: { name: "Pertinence", order: 1 },
                    },
                  },
                  on: {
                    "update:itemsContainer": function ($event) {
                      _vm.itemsContainer = $event
                    },
                    "update:items-container": function ($event) {
                      _vm.itemsContainer = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c("availability-filter", {
              attrs: { "items-container": _vm.itemsContainer },
              on: {
                "update:itemsContainer": function ($event) {
                  _vm.itemsContainer = $event
                },
                "update:items-container": function ($event) {
                  _vm.itemsContainer = $event
                },
              },
            }),
            _c("multiple-selection-facet", {
              attrs: {
                "show-count": "",
                "hide-if-filter-count-less-than": 1,
                "items-container": _vm.itemsContainer,
                header: "Départements",
                "facet-property": "MajorCategory",
                "all-text": _vm.$t("facets.allDepartments"),
                "request-facet-property": "majorCategory",
              },
              on: {
                "update:itemsContainer": function ($event) {
                  _vm.itemsContainer = $event
                },
                "update:items-container": function ($event) {
                  _vm.itemsContainer = $event
                },
              },
            }),
            _c("multiple-selection-facet", {
              attrs: {
                "show-count": "",
                "hide-if-filter-count-less-than": 1,
                "items-container": _vm.itemsContainer,
                header: "Catégories",
                "facet-property": "IntermediateCategory",
                "all-text": _vm.$t("facets.allCategories"),
                "request-facet-property": "intermediateCategory",
              },
              on: {
                "update:itemsContainer": function ($event) {
                  _vm.itemsContainer = $event
                },
                "update:items-container": function ($event) {
                  _vm.itemsContainer = $event
                },
              },
            }),
            _c("region-filter", {
              attrs: { "items-container": _vm.itemsContainer },
              on: {
                "update:itemsContainer": function ($event) {
                  _vm.itemsContainer = $event
                },
                "update:items-container": function ($event) {
                  _vm.itemsContainer = $event
                },
              },
            }),
            _c("label", { staticClass: "facetTitle" }, [
              _vm._v(_vm._s(_vm.$t("searchBar.title"))),
            ]),
            _c("search-filter", {
              attrs: {
                defaultValue: _vm.defaultSearchText,
                "items-container": _vm.itemsContainer,
                "facet-property": "search",
              },
              on: {
                "update:itemsContainer": function ($event) {
                  _vm.itemsContainer = $event
                },
                "update:items-container": function ($event) {
                  _vm.itemsContainer = $event
                },
              },
            }),
            _c("div", { staticClass: "btnSection" }, [
              _c("a", { staticClass: "btn", attrs: { id: "btnClose" } }, [
                _vm._v(_vm._s(_vm.$t("searchResults.close"))),
              ]),
            ]),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "right" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }