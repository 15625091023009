import shippingMethods from '@/models/store/ShippingMethod';

export const addShippingExtra = state => state.addShippingExtra;
export const cart = state => state.cart;
export const count = state =>
{
  if (state.cart) {
    let counter = 0;
    for (const product in state.cart) {
      counter += state.cart[product];
    }
    return counter;
  }
  return 0;
};

export const isDelivery = (state) => {
  return state.shippingMethod === shippingMethods.Delivery;
};

export const isPickup = (state) => {
  return state.shippingMethod === shippingMethods.Pickup;
};

export const products = state => state.products;

export const relatedProducts = state => state.relatedProducts;

export const shippingAddress = state => [
  state.shippingAddress.address,
  state.shippingAddress.city,
  state.shippingAddress.postalCode
].filter(item => Boolean(item)).join(', ');

export const shippingExtraPrice = state => state.shippingExtraPrice;

export const shippingPrice = state => state.shippingPrice;
