var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", {
    ref: "observer",
    attrs: { slim: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ invalid }) {
          return [
            _c(
              "div",
              {
                staticClass: "orderCustomer box",
                attrs: { id: "orderCustomer" },
              },
              [
                _c("div", { staticClass: "orderTitle" }, [
                  _c("h4", [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "10",
                          height: "11",
                          viewBox: "0 0 10 11",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
                            fill: "#E31F28",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("order.customer.title")) + " "),
                  ]),
                  _c("div", { staticClass: "requiredFieldLabel" }, [
                    _vm._v(_vm._s(_vm.$t("order.requiredField"))),
                  ]),
                ]),
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: "firstName",
                            rules: "required|max:50",
                            slim: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors, touched }) {
                                  return [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("order.customer.firstName")
                                          ) + "*"
                                        ),
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value: _vm.customer.firstName,
                                            expression: "customer.firstName",
                                            modifiers: { lazy: true },
                                          },
                                        ],
                                        class: {
                                          invalid: touched && errors.length,
                                        },
                                        attrs: {
                                          type: "text",
                                          autocomplete: "shipping given-name",
                                        },
                                        domProps: {
                                          value: _vm.customer.firstName,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.customer,
                                              "firstName",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("validation-provider", {
                          attrs: {
                            name: "lastName",
                            rules: "required|max:50",
                            slim: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors, touched }) {
                                  return [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("order.customer.lastName")
                                          ) + "*"
                                        ),
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value: _vm.customer.lastName,
                                            expression: "customer.lastName",
                                            modifiers: { lazy: true },
                                          },
                                        ],
                                        class: {
                                          invalid: touched && errors.length,
                                        },
                                        attrs: {
                                          type: "text",
                                          autocomplete: "shipping family-name",
                                        },
                                        domProps: {
                                          value: _vm.customer.lastName,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.customer,
                                              "lastName",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c("order-billing", {
                      attrs: {
                        addressCompleteSettings: _vm.addressCompleteSettings,
                        user: _vm.user,
                      },
                      on: {
                        addressUpdated: function ($event) {
                          _vm.$nextTick(() => _vm.$refs.observer.validate())
                        },
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: "email",
                            rules: "required|email|max:255",
                            slim: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors, touched }) {
                                  return [
                                    _c("div", { staticClass: "formItem" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("order.customer.email")
                                          ) + "*"
                                        ),
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value: _vm.customer.email,
                                            expression: "customer.email",
                                            modifiers: { lazy: true },
                                          },
                                        ],
                                        class: {
                                          invalid: touched && errors.length,
                                        },
                                        attrs: {
                                          type: "email",
                                          autocomplete: "shipping email",
                                        },
                                        domProps: { value: _vm.customer.email },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.customer,
                                              "email",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("validation-provider", {
                          attrs: {
                            name: "emailConfirmation",
                            rules: "required|email|max:255|emailMatch",
                            slim: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors, touched }) {
                                  return [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "order.customer.emailConfirmation"
                                            )
                                          ) + "*"
                                        ),
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              _vm.customer.emailConfirmation,
                                            expression:
                                              "customer.emailConfirmation",
                                            modifiers: { lazy: true },
                                          },
                                        ],
                                        class: {
                                          invalid: touched && errors.length,
                                        },
                                        attrs: {
                                          type: "email",
                                          autocomplete:
                                            "shipping email-confirmation",
                                        },
                                        domProps: {
                                          value: _vm.customer.emailConfirmation,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.customer,
                                              "emailConfirmation",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: "phone",
                            rules: "required|phone|max:20",
                            slim: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("order.customer.phone")
                                            ) + "*"
                                          ),
                                        ]),
                                        _c("the-mask", {
                                          class: {
                                            invalid:
                                              _vm.isPhoneNumberTouched &&
                                              errors.length > 0,
                                          },
                                          attrs: {
                                            type: "tel",
                                            mask: "(###) ###-####",
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.$nextTick(() =>
                                                _vm.$refs.observer.validate()
                                              )
                                            },
                                          },
                                          nativeOn: {
                                            blur: function ($event) {
                                              return _vm.setPhoneNumberTouched.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.customer.phone,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                "phone",
                                                $$v
                                              )
                                            },
                                            expression: "customer.phone",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("div"),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row checkbox" }, [
                      _c(
                        "div",
                        {
                          staticClass: "checkboxContainer",
                          on: {
                            click: function ($event) {
                              _vm.customer.subscribeNewsletter =
                                !_vm.customer.subscribeNewsletter
                            },
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customer.subscribeNewsletter,
                                expression: "customer.subscribeNewsletter",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.customer.subscribeNewsletter
                              )
                                ? _vm._i(
                                    _vm.customer.subscribeNewsletter,
                                    null
                                  ) > -1
                                : _vm.customer.subscribeNewsletter,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.customer.subscribeNewsletter,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.customer,
                                        "subscribeNewsletter",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.customer,
                                        "subscribeNewsletter",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.customer,
                                    "subscribeNewsletter",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _c("span", { staticClass: "checkmark" }),
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("order.customer.subscribeNewsletter")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "row checkbox" },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: "pickupfirstName",
                            rules: { required: { allowFalse: false } },
                            slim: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("div", { staticClass: "terms" }, [
                                      _c(
                                        "label",
                                        { staticClass: "checkboxContainer" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.acceptedTermsConditions,
                                                expression:
                                                  "acceptedTermsConditions",
                                              },
                                            ],
                                            class: { invalid: errors.length },
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.acceptedTermsConditions
                                              )
                                                ? _vm._i(
                                                    _vm.acceptedTermsConditions,
                                                    null
                                                  ) > -1
                                                : _vm.acceptedTermsConditions,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.acceptedTermsConditions,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.acceptedTermsConditions =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.acceptedTermsConditions =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.acceptedTermsConditions =
                                                    $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "/mentions-legales",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "order.payment.termsConditions"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "orderTitle row" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "10",
                            height: "11",
                            viewBox: "0 0 10 11",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
                              fill: "#E31F28",
                            },
                          }),
                        ]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("order.shipping.title")) + " "
                      ),
                    ]),
                    _vm.hasRegularAndBarcodeItems(_vm.products)
                      ? _c("div", { staticClass: "billSplitMentionning" }, [
                          _c("img", {
                            attrs: { src: "/images/info-icon.png" },
                          }),
                          _c("p", { staticClass: "firstLine" }, [
                            _vm._v(_vm._s(_vm.$t("product.billSplitMention"))),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "orderShipping" },
                      [
                        _c("div", { staticClass: "shippingSelector row" }, [
                          _c("div", { staticClass: "radioOptions" }, [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "checkboxContainer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setShipping(
                                        _vm.shippingMethods.Pickup
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedShippingMethod,
                                        expression: "selectedShippingMethod",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "pickup",
                                      name: "selectedShippingMethod",
                                    },
                                    domProps: {
                                      value: _vm.shippingMethods.Pickup,
                                      checked: _vm._q(
                                        _vm.selectedShippingMethod,
                                        _vm.shippingMethods.Pickup
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.selectedShippingMethod =
                                          _vm.shippingMethods.Pickup
                                      },
                                    },
                                  }),
                                  _c("span", { staticClass: "radio" }),
                                  _c("label", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("cart.shippingMethod.pickup")
                                        )
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "cart.shippingMethod.pickupDescription",
                                            { fromHours: 3 }
                                          )
                                        ) + " : "
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("cart.shippingMethod.free")
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  class: {
                                    checkboxContainer: true,
                                    disabled: _vm.shippingDisabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setShipping(
                                        _vm.shippingMethods.Delivery
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedShippingMethod,
                                        expression: "selectedShippingMethod",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "shipping",
                                      name: "selectedShippingMethod",
                                      disabled: _vm.shippingDisabled,
                                    },
                                    domProps: {
                                      value: _vm.shippingMethods.Delivery,
                                      checked: _vm._q(
                                        _vm.selectedShippingMethod,
                                        _vm.shippingMethods.Delivery
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.selectedShippingMethod =
                                          _vm.shippingMethods.Delivery
                                      },
                                    },
                                  }),
                                  _c("span", { staticClass: "radio" }),
                                  _c("label", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("cart.shippingMethod.delivery")
                                        )
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "cart.shippingMethod.deliveryDescription",
                                            { fromDays: 1, toDays: 7 }
                                          )
                                        )
                                      ),
                                      _vm.showShippingPriceExtraLabel
                                        ? _c("span", [
                                            _vm._v(
                                              " : " +
                                                _vm._s(
                                                  _vm.$root.formatPrice(
                                                    _vm.shippingPrice
                                                  )
                                                )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _c("span", { staticClass: "extraLabel" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "cart.shippingMethod.deliveryDescriptionHours",
                                            {
                                              fromHours: "8h00",
                                              toHours: "17h00",
                                            }
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("span", { staticClass: "extraLabel" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "cart.shippingMethod.deliveryDescriptionMaxDistance",
                                            { maxDistanceInKm: 110 }
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm.isDistanceTooHigh
                                      ? _c(
                                          "small",
                                          { staticClass: "distanceTooHigh" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "cart.shippingErrors.distanceTooHigh"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", [
                              _vm.shippingMethod ===
                              _vm.shippingMethods.Delivery
                                ? _c(
                                    "div",
                                    { class: { checkboxContainer: true } },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          id: "shippingExtra",
                                        },
                                        domProps: {
                                          checked: _vm.addShippingExtra,
                                          value: _vm.addShippingExtra,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setAddShippingExtra(
                                              !_vm.addShippingExtra
                                            )
                                          },
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        on: {
                                          click: function ($event) {
                                            return _vm.setAddShippingExtra(
                                              !_vm.addShippingExtra
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "shippingExtra" } },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "cart.shippingExtraMessage"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "extraLabel" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "cart.shippingExtraConditions"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "extraLabel" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "cart.shippingExtraFee",
                                                    {
                                                      fee: _vm.shippingExtraPrice,
                                                    }
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                        _vm.shippingMethod === _vm.shippingMethods.Pickup
                          ? _c("order-pickup")
                          : _vm._e(),
                        _vm.shippingMethod === _vm.shippingMethods.Delivery
                          ? _c("delivery-date-selection")
                          : _vm._e(),
                        _vm.shippingMethod === _vm.shippingMethods.Delivery
                          ? _c(
                              "div",
                              [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("order.payment.ShippingAddress")
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("shipping-address", {
                                  attrs: {
                                    addressCompleteSettings:
                                      _vm.addressCompleteSettings,
                                    user: _vm.user,
                                  },
                                  on: {
                                    addressUpdated: function ($event) {
                                      _vm.$nextTick(() =>
                                        _vm.$refs.observer.validate()
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.shippingMethod === _vm.shippingMethods.Pickup
                      ? _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "checkboxContainer",
                              on: {
                                click: function ($event) {
                                  _vm.pickupMyself = !_vm.pickupMyself
                                },
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.notPickupMyself,
                                    expression: "notPickupMyself",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.notPickupMyself)
                                    ? _vm._i(_vm.notPickupMyself, null) > -1
                                    : _vm.notPickupMyself,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.notPickupMyself,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.notPickupMyself = $$a.concat([
                                            $$v,
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.notPickupMyself = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.notPickupMyself = $$c
                                    }
                                  },
                                },
                              }),
                              _c("span", { staticClass: "checkmark" }),
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("order.customer.pickupMyself"))
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.shippingMethod === _vm.shippingMethods.Pickup &&
                    _vm.notPickupMyself
                      ? _c("div", { staticClass: "moreInfos" }, [
                          _c("h4", [
                            _vm._v(
                              _vm._s(_vm.$t("order.customer.pickupNameLabel"))
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "pickupfirstName",
                                  rules: "required|max:50",
                                  slim: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "order.customer.firstName"
                                                    )
                                                  ) + "*"
                                                ),
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.lazy",
                                                    value:
                                                      _vm.pickupContact
                                                        .firstName,
                                                    expression:
                                                      "pickupContact.firstName",
                                                    modifiers: { lazy: true },
                                                  },
                                                ],
                                                class: {
                                                  invalid: errors.length,
                                                },
                                                attrs: {
                                                  type: "text",
                                                  autocomplete:
                                                    "shipping given-name",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.pickupContact.firstName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      _vm.pickupContact,
                                                      "firstName",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("validation-provider", {
                                attrs: {
                                  name: "pickupLastName",
                                  rules: "required|max:50",
                                  slim: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "order.customer.lastName"
                                                    )
                                                  ) + "*"
                                                ),
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.lazy",
                                                    value:
                                                      _vm.pickupContact
                                                        .lastName,
                                                    expression:
                                                      "pickupContact.lastName",
                                                    modifiers: { lazy: true },
                                                  },
                                                ],
                                                class: {
                                                  invalid: errors.length,
                                                },
                                                attrs: {
                                                  type: "text",
                                                  autocomplete:
                                                    "shipping family-name",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.pickupContact.lastName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      _vm.pickupContact,
                                                      "lastName",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "pickupEmail",
                                  rules: "required|max:255",
                                  slim: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "order.customer.email"
                                                    )
                                                  ) + "*"
                                                ),
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.lazy",
                                                    value:
                                                      _vm.pickupContact.email,
                                                    expression:
                                                      "pickupContact.email",
                                                    modifiers: { lazy: true },
                                                  },
                                                ],
                                                class: {
                                                  invalid: errors.length,
                                                },
                                                attrs: {
                                                  type: "email",
                                                  autocomplete:
                                                    "shipping email",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.pickupContact.email,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      _vm.pickupContact,
                                                      "email",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("validation-provider", {
                                attrs: {
                                  name: "pickupEmailConfirmation",
                                  rules: "required|max:255|pickupEmailMatch",
                                  slim: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "order.customer.emailConfirmation"
                                                    )
                                                  ) + "*"
                                                ),
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.lazy",
                                                    value:
                                                      _vm.pickupContact
                                                        .emailConfirmation,
                                                    expression:
                                                      "pickupContact.emailConfirmation",
                                                    modifiers: { lazy: true },
                                                  },
                                                ],
                                                class: {
                                                  invalid: errors.length,
                                                },
                                                attrs: {
                                                  type: "email",
                                                  autocomplete:
                                                    "shipping email-confirmation",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.pickupContact
                                                      .emailConfirmation,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      _vm.pickupContact,
                                                      "emailConfirmation",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("order-details", {
                      staticClass: "m",
                      attrs: {
                        gst: _vm.gst,
                        "promo-code-configuration": _vm.promoCodeConfiguration,
                        qst: _vm.qst,
                        shipping:
                          _vm.shippingMethod === _vm.shippingMethods.Delivery
                            ? _vm.shippingPrice
                            : null,
                        "sub-total": _vm.subTotal(),
                        total: _vm.total(),
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "legalnotices",
                            fn: function () {
                              return [_vm._t("legalnotices")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm.orderStep < _vm.OrderStep.Payment
                      ? _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: {
                              type: "submit",
                              disabled: invalid || _vm.isSubmitButtonDisabled,
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("order.customer.submit")))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }