<template>
  <div class="registration">
    <div class="registerForm-wrapper">
      <validation-observer class="registerForm accountForm" tag="form" ref="registerForm" @submit.prevent="submit" novalidate>
        <div class="row">
          <validation-provider class="formItem" tag="div" name="firstName" rules="required|max:50" v-slot="{ errors }" slim>
            <label>{{ $t('users.fields.firstName') }}*</label>
            <input type="text" v-model="firstName" name="firstName" :class="{ invalid: errors.length }" autocomplete="firstName" required />
            <div class="error" v-if="errors.length">{{ errors[0] }}</div>
          </validation-provider>
          
          <validation-provider class="formItem" tag="div" name="lastName" rules="required|max:50" v-slot="{ errors }" slim>
            <label>{{ $t('users.fields.lastName') }}*</label>
            <input type="text" v-model="lastName" name="lastName" :class="{ invalid: errors.length }" autocomplete="lastName" required />
            <div class="error" v-if="errors.length">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        
        <div class="row">
          <validation-provider class="formItem" tag="div" name="email" rules="required|email|max:255" v-slot="{ errors }" slim>
            <label>{{ $t('users.fields.email') }}*</label>
            <input type="email" name="email" v-model="email" :class="{ invalid: errors.length }" autocomplete="email" required />
            <div class="error" v-if="errors.length">{{ errors[0] }}</div>
          </validation-provider>
          
          <validation-provider class="formItem" tag="div" name="phone" rules="required|phone|max:20" v-slot="{ errors }" slim>
            <label>{{ $t('users.fields.phone') }}*</label>
            <the-mask type="tel" mask="### ###-####" v-model="phone" :class="{ invalid: errors.length }" autocomplete="phone" required />
            <div class="error" v-if="errors.length">{{ errors[0] }}</div>
          </validation-provider>
        </div>

        <div class="row">
          <validation-provider class="formItem" tag="div" name="addressLine1" rules="required|max:50|insideQc:@province" v-slot="{ errors }" ref="addressLine">
            <label for="editBillingInfoForm-addressLineCombobox">{{ $t('order.billing.address') }}*</label>
            <canada-post-search
              v-model.lazy="address.addressLine"
              id="editBillingInfoForm-addressLineCombobox"
              :addressCompleteSettings="addressCompleteSettings"
              :invalid="errors.length > 0"
              searchFieldId="address"
              ref="canadaPostSearch"
              @updateAddress="updateCPAddress"
              @ready:addressComplete="handleAddressCompleteReady" />
            <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
            <small class="hintText">{{ $t("users.hints.deliveryOnlyInQuebec") }}</small>
          </validation-provider>
        </div>

        <div class="row">
          <validation-provider class="formItem" tag="div" name="province" mode="aggressive" v-slot="{ errors }" hidden>
            <input id="editBillingInfoForm-provinceInput" v-model="province" type="hidden" />
            <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
          </validation-provider>

          <validation-provider class="formItem" tag="div" name="city" rules="required|max:50" v-slot="{ errors }">
            <label for="editBillingInfoForm-cityInput">{{ $t('order.billing.city') }}*</label>
            <input id="editBillingInfoForm-cityInput" v-model="address.city" type="text" :class="{ invalid: errors.length }" />
            <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
          </validation-provider>

          <validation-provider class="formItem" tag="div" name="postalCode" rules="required|max:50" v-slot="{ errors }">
            <label for="editBillingInfoForm-postalCodeInput">{{ $t('order.billing.postalCode') }}*</label>
            <the-mask id="editBillingInfoForm-postalCodeInput" type="text" mask="A#A#A#" v-model.lazy="address.postalCode" :class="{ invalid: errors.length }" />
            <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        
        <div class="row">
          <validation-provider class="formItem" tag="div" name="password" rules="required|password" v-slot="{ errors }" slim>
            <label>{{ $t('users.fields.password') }}*</label>
            <password-container v-model="password" :errors="errors"></password-container>
            <div class="error" v-if="errors.length">{{ errors[0] }}</div>
            <ul class="criterias">
              <li>{{ $t('users.messages.passwordCriterias.characters')}}</li>
              <li>{{ $t('users.messages.passwordCriterias.length')}}</li>
              <li>{{ $t('users.messages.passwordCriterias.specialCharacters')}}</li>
              <li>{{ $t('users.messages.passwordCriterias.numbers')}}</li>
            </ul>
          </validation-provider>
        
          <validation-provider class="formItem" tag="div" name="passwordConfirmation" rules="required|passwordMatch:@password" v-slot="{ errors }" slim>
            <label>{{ $t('users.fields.passwordConfirmation') }}*</label>
            <password-container v-model="passwordConfirmation" :errors="errors"></password-container>
            <div class="error" v-if="errors.length">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <working-button class="btn" type="submit" :isWorking="isCreatingAccount" :label="submitLabel"></working-button>
      </validation-observer>

      <div class="privacyPolicyLinkContainer">
        <a :href="privacyPolicyUrl">{{ $t('users.links.privacyPolicy') }}</a>
        <span>&#183;</span>
        <a :href="conditionsUrl">{{ $t('users.links.conditions') }}</a>
      </div>
      
      <dialog-modal class="wrapper_modal" :isOpen="showEmailSentModal" :title="$t('accountEmailSentModal.title')" @close:dialog="handleCloseEmailSentDialog">
        <p>{{ $t('accountEmailSentModal.text') }}</p>
      </dialog-modal>
      
      <dialog-modal class="wrapper_modal" :isOpen="showValidationModal" :title="$t('accountValidationModal.title')" @close:dialog="handleCloseAccountValidationDialog">
        <account-creation-validation :email="email" :firstName="firstName" :identifier="identifier"></account-creation-validation>
      </dialog-modal>
      
      <dialog-modal class="wrapper_modal" :isOpen="registrationErrorDialogIsOpen" :title="$t('accountRegistrationErrorModal.title')" @close:dialog="handleCloseRegistrationErrorDialog">
        <div v-if="registrationErrorType" v-html="$t(`accountRegistrationErrorModal.${registrationErrorType}`)"></div>
      </dialog-modal>
    </div>
    <hr />
    <div class="login-socialLogins">
      <slot />
      <div class="privacyPolicyLinkContainer">
        <a :href="privacyPolicyUrl">{{ $t('users.links.privacyPolicy') }}</a>
        <span>&#183;</span>
        <a :href="conditionsUrl">{{ $t('users.links.conditions') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { TheMask } from 'vue-the-mask'
import PasswordContainer from '../Forms/PasswordContainer.vue'
import DialogModal from '../Utilities/DialogModal.vue'
import { setFocusOnFirstErrorField } from '../../helpers/domHelper'
import WorkingButton from '../Forms/WorkingButton.vue'
import AccountCreationValidation from '../Forms/AccountCreationValidation.vue'
import CanadaPostSearch from '@/components/CanadaPostSearch'

setInteractionMode('eager')

const httpCodeMap = new Map()
httpCodeMap.set(409, 'RegisterExistingUserException')

export default {
  components: {
    AccountCreationValidation,
    CanadaPostSearch,
    DialogModal,
    PasswordContainer,
    TheMask,
    ValidationObserver,
    ValidationProvider,
    WorkingButton
  },
  props: {
    addressCompleteSettings: {
      type: Object,
      required: true
    },
    conditionsUrl: {
      type: String,
      required: true
    },
    connectionUrl: {
      type: String,
      required: true
    },
    privacyPolicyUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      address: {},
      email: '',
      firstName: '',
      identifier: '',
      isCreatingAccount: false,
      lastName: '',
      password: '',
      passwordConfirmation: '',
      phone: '',
      province: '',
      registrationErrorDialogIsOpen: false,
      registrationErrorType: undefined,
      showEmailSentModal: false,
      showValidationModal: false
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('identifier')) {
      this.identifier = urlParams.get('identifier')
      this.email = urlParams.get('email')
      this.firstName = urlParams.get('firstname')
      this.showValidationModal = true
    }
  },
  methods: {
    handleAddressCompleteReady() {
      const pcaElement = document.querySelector('.pca')
      if(!pcaElement) {
        return
      }

      this.$refs.canadaPostSearch.$el.appendChild(pcaElement)
    },
    handleCloseRegistrationErrorDialog() {
      this.registrationErrorDialogIsOpen = false
    },
    handleCloseEmailSentDialog() {
      this.showEmailSentModal = false
      window.location.href = this.connectionUrl;
    },
    handleCloseAccountValidationDialog() {
      this.showValidationModal = false
    },
    async submit() {
      let isValid = await this.$refs.registerForm.validate()

      if (!isValid || this.isPasswordConfirmationInvalid) {
        setFocusOnFirstErrorField(this.$refs.registerForm.$el, '.invalid')
        return
      }

      this.isCreatingAccount =  true
      try {
        const model = {
          address: this.address,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password,
          phone: this.phone
        }
        const response = await this.$axios.post('/Umbraco/Api/Authentication/Register', model)
        this.showEmailSentModal = true      
      }
      catch (error) {
        this.registrationErrorType = httpCodeMap.get(error.response.status)

        if(!this.registrationErrorType) {
          this.registrationErrorType = 'RegisterException'
        }
        
        this.registrationErrorDialogIsOpen = true
      }
      finally {
        this.isCreatingAccount = false
      }
    },
    updateCPAddress(address) {
      this.address = { addressLine : address.Line1, city: address.City, postalCode: address.PostalCode }
      this.province = address.ProvinceCode
    }
  },
  computed: {
    isPasswordConfirmationInvalid() {
      if (!this.password || !this.passwordConfirmation) {
        return true
      }
      return this.password !== this.passwordConfirmation
    },
    submitLabel() {
      if(this.isCreatingAccount) {
        return this.$t('users.actions.registering')
      }

      return this.$t('users.actions.register')
    }
  }
}
</script>