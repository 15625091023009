var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "radioButtonFacet desktop" }, [
      _c("label", { staticClass: "facetTitle" }, [
        _vm._v(" " + _vm._s(_vm.$t("facets.regions")) + " "),
      ]),
      _c(
        "div",
        [
          _vm._l(_vm.staticFacets, function (facet, index) {
            return _c(
              "div",
              {
                key: index,
                class: [
                  "facetItem",
                  { selected: _vm.selectedFacets.includes(facet.value) },
                ],
              },
              [
                _c("div", { staticClass: "choiceFacet" }, [
                  _c(
                    "span",
                    {
                      class: _vm.selectedFacets.includes(facet.value)
                        ? "selected"
                        : "",
                      on: {
                        click: function ($event) {
                          return _vm.toggleFacet(facet)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.getFacetText(facet.value)) + " ")]
                  ),
                ]),
              ]
            )
          }),
          _c(
            "div",
            {
              class: [
                "facetItem",
                "all",
                { selected: _vm.selectedFacets.length === 0 },
              ],
            },
            [
              _c("span", { on: { click: _vm.resetFacets } }, [
                _vm._v(_vm._s(_vm.$t("facets.allRegions"))),
              ]),
            ]
          ),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "dropdownFacet mobile" }, [
      _c("label", { staticClass: "facetTitle" }, [
        _vm._v(_vm._s(_vm.$t("facets.regions"))),
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "activator", on: { click: _vm.toggleDropdown } },
          [_c("span", [_vm._v(_vm._s(_vm.dropdownValues))])]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDropdownOpen,
                expression: "isDropdownOpen",
              },
            ],
            staticClass: "facets",
          },
          [
            _c(
              "div",
              {
                class: [
                  "facetItem",
                  "all",
                  { selected: _vm.selectedFacets.length === 0 },
                ],
                on: { click: _vm.resetFacets },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("facets.allRegions")))])]
            ),
            _vm._l(_vm.staticFacets, function (facet, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: [
                    "facetItem",
                    { selected: _vm.selectedFacets.includes(facet.value) },
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.toggleFacet(facet)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      class: _vm.selectedFacets.includes(facet.value)
                        ? "selected"
                        : "",
                      on: {
                        click: function ($event) {
                          return _vm.toggleFacet(facet)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.getFacetText(facet.value)) + " ")]
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }