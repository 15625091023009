<template>
  <div>
    <div class="radioButtonFacet desktop">
      <label class="facetTitle">
        {{ $t('facets.regions') }}
      </label>
      <div>
        <div
          v-for="(facet, index) in staticFacets"
          :key="index"
          :class="['facetItem', { selected: selectedFacets.includes(facet.value) }]"
        >
          <div class="choiceFacet">
            <span @click="toggleFacet(facet)" :class="selectedFacets.includes(facet.value) ? 'selected' : ''">
              {{ getFacetText(facet.value) }}
            </span>
          </div>
        </div>
        <div
          :class="['facetItem', 'all', { selected: selectedFacets.length === 0 }]"
        >
          <span @click="resetFacets">{{ $t('facets.allRegions') }}</span>
        </div>
      </div>
    </div>
    <div class="dropdownFacet mobile">
      <label class="facetTitle">{{ $t('facets.regions') }}</label>
      <div>
        <div class="activator" @click="toggleDropdown">
          <span>{{ dropdownValues }}</span>
        </div>
        <div class="facets" v-show="isDropdownOpen">
          <div
            @click="resetFacets"
            :class="['facetItem', 'all', { selected: selectedFacets.length === 0 }]"
          >
            <span>{{ $t('facets.allRegions') }}</span>
          </div>
          <div
            v-for="(facet, index) in staticFacets"
            :key="index"
            :class="['facetItem', { selected: selectedFacets.includes(facet.value) }]"
            @click="toggleFacet(facet)"
          >
            <span @click="toggleFacet(facet)" :class="selectedFacets.includes(facet.value) ? 'selected' : ''">
              {{ getFacetText(facet.value) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';
import hashMixins from '@/hashMixins';

export default {
  components: {
    Checkbox
  },
  props: {
    itemsContainer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hashParams: null,
      isDropdownOpen: false,
      selectedFacets: [],
      staticFacets: [
        { name: 'region', value: 'providerInCanada' },
        { name: 'region', value: 'providerInQuebec' },
        { name: 'region', value: 'madeInCanada' },
        { name: 'region', value: 'madeInQuebec' }
      ]
    };
  },
  computed: {
    dropdownValues() {
      return this.selectedFacets.length > 0 ? this.getFacetText(this.selectedFacets[0]) : this.$t('facets.allRegions');
    }
  },
  methods: {
    getPropertyFacets() {
      if (this.hashParams['regions'] != null) {
        this.selectedFacets = this.getListFromParam(this.hashParams['regions']);
      }   
      this.updateItemsContainer();
    },
    toggleFacet(facet) {
      if (this.selectedFacets.indexOf(facet.value) === -1) {
        this.selectedFacets = [ facet.value ];
      } else {
        this.selectedFacets = [];
      }

      this.itemsContainer.lastSelectedFiltrationFacetName = facet.name;
      this.$root.$emit('resetPage');
      this.updateItemsContainer();
    },
    resetFacets() {
      this.selectedFacets = [];
      this.updateItemsContainer();

      this.isDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    getFacetText(facetText) {
      return this.$t(`regions.${facetText}`) || facetText || 'Other';
    },
    updateItemsContainer() {
      this.itemsContainer.selectedFacets = {
        ...this.itemsContainer.selectedFacets,
        regions: this.selectedFacets
      };
    }
  },
  created() {
    this.hashParams = this.getHashParams();
  },
  watch: {
    async 'itemsContainer.filtrationFacets'() {
      this.getPropertyFacets();
    }
  },
  mixins: [hashMixins]
};
</script>

<style lang="scss" scoped>
</style>