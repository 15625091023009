var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", { staticClass: "favoriteProduct" }, [
    _c("div", { staticClass: "favoriteProduct-container" }, [
      _c("div", { staticClass: "imageSection" }, [
        _c("img", {
          staticClass: "favoriteProduct-image",
          attrs: {
            src:
              _vm.product.image && _vm.product.image.url
                ? _vm.product.image.url
                : _vm.fallbackProductImageUrl,
            alt: "",
          },
          on: { error: _vm.setFallbackProductImage },
        }),
      ]),
      _c("div", { staticClass: "dataSection" }, [
        _c("div", { staticClass: "header" }, [
          _c("h3", { staticClass: "productName" }, [
            _c(
              "a",
              {
                staticClass: "favoriteProduct-productLink",
                attrs: { href: _vm.product.url },
              },
              [_vm._v(_vm._s(_vm.product.name))]
            ),
          ]),
          _vm.product.isWebExclusive != null &&
          _vm.product.isWebExclusive == true
            ? _c("div", { staticClass: "pill" }, [
                _vm._v(" " + _vm._s(_vm.$t("product.pill.webOnly")) + " "),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "productInfos" }, [
          _c("div", { staticClass: "majorCategory" }, [
            _c("span", [_vm._v(_vm._s(_vm.product.majorCategory))]),
            !_vm.isAvailable
              ? _c("p", { staticClass: "warning" }, [
                  _vm._v(_vm._s(_vm.$t("myFavorites.notAvailable"))),
                ])
              : _vm._e(),
          ]),
          _vm.product.promoSquareFootPrice != null &&
          _vm.product.squareFootPrice != null
            ? _c("div", { staticClass: "prices" }, [
                _c("div", { staticClass: "promotionPrices" }, [
                  _vm.squareFootPrice
                    ? _c(
                        "span",
                        { class: { stroke: _vm.product.hasPromotion } },
                        [
                          _vm._v(
                            _vm._s(_vm.squareFootPrice) +
                              _vm._s(_vm.$t("product.squareFootPriceUnit"))
                          ),
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("product.availability.priceUnavailable")
                          )
                        ),
                      ]),
                  _vm.product.hasPromotion
                    ? _c("span", { staticClass: "redPrice" }, [
                        _vm._v(
                          _vm._s(_vm.promoSquareFootPrice) +
                            _vm._s(_vm.$t("product.squareFootPriceUnit"))
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm.product.squareFootPrice != null
            ? _c("div", { staticClass: "prices" }, [
                _c("div", { staticClass: "promotionPrices" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.squareFootPrice) +
                        " " +
                        _vm._s(_vm.$t("product.squareFootPriceUnit"))
                    ),
                  ]),
                ]),
              ])
            : _c("div", { staticClass: "prices" }, [
                _c("div", { staticClass: "promotionPrices" }, [
                  _vm.price
                    ? _c(
                        "span",
                        { class: { stroke: _vm.product.hasPromotion } },
                        [_vm._v(_vm._s(_vm.price))]
                      )
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("product.availability.priceUnavailable")
                          )
                        ),
                      ]),
                  _vm.product.hasPromotion
                    ? _c("span", { staticClass: "redPrice" }, [
                        _vm._v(_vm._s(_vm.bestPrice)),
                      ])
                    : _vm._e(),
                ]),
              ]),
        ]),
        _c("div", { staticClass: "productInfos" }, [
          _c("div", { staticClass: "productInfosStore" }, [
            _vm.isBarcodeItem(_vm.product) || _vm.isWebExclusive(_vm.product)
              ? _c("div", { staticClass: "inStock" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("product.availability.inStock")) + " "
                  ),
                ])
              : !_vm.isUnavailable(_vm.product)
              ? _c("div", { staticClass: "inStock" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("product.availability.inStockWithValue", {
                          value: _vm.getQuantity(_vm.product).value,
                        })
                      ) +
                      " "
                  ),
                ])
              : !_vm.isInactive(_vm.product) && !_vm.isInStock(_vm.product)
              ? _c("div", { staticClass: "notInStock" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("product.availability.notInStock")) +
                      " "
                  ),
                ])
              : _vm.isPromotional(_vm.product)
              ? _c("div", { staticClass: "unavailable" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("product.availability.promotional")) +
                      " "
                  ),
                ])
              : _vm.isRecall(_vm.product)
              ? _c("div", { staticClass: "unavailable" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("product.availability.recall")) + " "
                  ),
                ])
              : _c("div", { staticClass: "unavailable" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("product.availability.unavailable")) +
                      " "
                  ),
                ]),
          ]),
          _c(
            "div",
            {
              staticClass: "addCartSection",
              class: _vm.$root
                .getFeatureFlag("ecommerce")
                .toString()
                .toLowerCase(),
            },
            [
              !_vm.isUnavailable(_vm.product)
                ? _c("add-to-cart-button", {
                    attrs: {
                      disabled: _vm.isUnavailable(_vm.product),
                      maximumQuantity: _vm.getQuantityValue(_vm.product),
                      productId: _vm.product.identifier,
                      product: _vm.product,
                      quantity: 1,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "deleteButton",
              attrs: { type: "button", disabled: _vm.isRemovingFavorite },
              on: { click: _vm.removeFavorite },
            },
            [
              _vm.isRemovingFavorite
                ? _c("img", { attrs: { src: "/images/triangle-loading.gif" } })
                : _c("trash-can"),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("users.actions.removeFromFavorites"))),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "favoriteProduct-actions" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }