var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        cartProduct: true,
        isCartProductDisabled: _vm.isCartProductDisabled,
      },
    },
    [
      _c("div", { staticClass: "productInfosImg" }, [
        _c("div", { staticClass: "img" }, [
          _c("a", { attrs: { href: _vm.product.url } }, [
            _vm.product.images && _vm.product.images.length
              ? _c("img", {
                  attrs: {
                    onerror: "this.src='/images/image-default.png';",
                    src:
                      _vm.product.images[0].url != null
                        ? _vm.product.images[0].url
                        : "/images/image-default.png",
                    alt: _vm.product.images[0].alt,
                    loading: "lazy",
                  },
                })
              : _c("img", {
                  attrs: {
                    src: "/images/image-default.png",
                    alt: "Image par d�faut",
                    loading: "lazy",
                  },
                }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "productInfos" },
          [
            _c("div", { staticClass: "name" }, [
              _c("a", { attrs: { href: _vm.url } }, [
                _vm._v(_vm._s(_vm.product.name)),
              ]),
            ]),
            _vm._t("default", null, { product: _vm.product }),
            !_vm.deleting
              ? _c("div", [
                  _c(
                    "a",
                    { staticClass: "delete", on: { click: _vm.handleDelete } },
                    [_vm._v(_vm._s(_vm.$t("actions.remove")))]
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "unityPrice" },
        [
          !this.$root.getFeatureFlag("hidePrices")
            ? [
                _vm.price !== null && _vm.price.discounted
                  ? _c("div", { staticClass: "price discounted" }, [
                      _c("div", { staticClass: "wDiscount" }, [
                        _vm._v(
                          _vm._s(_vm.$root.formatPrice(_vm.price.discounted))
                        ),
                      ]),
                      _c("div", { staticClass: "original" }, [
                        _vm._v(_vm._s(_vm.$root.formatPrice(_vm.price.value))),
                      ]),
                    ])
                  : _vm.price !== null &&
                    _vm.activePromoCode != null &&
                    this.isPromoCodeValid
                  ? _c("div", { staticClass: "price promoCodeDiscounted" }, [
                      _c("div", { staticClass: "wDiscount" }, [
                        _vm._v(
                          _vm._s(_vm.$root.formatPrice(_vm.promoCodePrice))
                        ),
                      ]),
                      _c("div", { staticClass: "original" }, [
                        _vm._v(_vm._s(_vm.$root.formatPrice(_vm.price.value))),
                      ]),
                    ])
                  : _vm.price !== null
                  ? _c("div", { staticClass: "price regular" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$root.formatPrice(_vm.price.value))),
                      ]),
                    ])
                  : _c("div", { staticClass: "price regular" }, [
                      _c("span", [_vm._v("N/D")]),
                    ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("div", { staticClass: "quantityContent" }, [
        !_vm.deleting
          ? _c(
              "div",
              [
                _c("quantity-selector", {
                  attrs: {
                    disabled: _vm.isCartProductDisabled,
                    "maximum-quantity": _vm.maximumQuantity,
                  },
                  model: {
                    value: _vm.quantity,
                    callback: function ($$v) {
                      _vm.quantity = $$v
                    },
                    expression: "quantity",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "priceTotal" },
        [
          !this.$root.getFeatureFlag("hidePrices")
            ? [
                _vm.total !== null
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.$root.formatPrice(_vm.total))),
                    ])
                  : _c("span", [_vm._v("N/D")]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }