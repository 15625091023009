var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loading" }, [
            _c("img", { attrs: { src: "/images/triangle-loading.gif" } }),
          ])
        : _vm._l(_vm.summaries, function (summary) {
            return _c("div", { staticClass: "orderconfirmationItem" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "summary" }, [
                  summary
                    ? _c(
                        "div",
                        [
                          _c("h2", [
                            _vm._v(
                              _vm._s(_vm.$t("orderConfirmation.confirmed"))
                            ),
                          ]),
                          _c("div", { staticClass: "row clientRow" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$t("orderConfirmation.client"))
                                ),
                              ]),
                              _c("span", [_vm._v(_vm._s(summary.clientName))]),
                            ]),
                            _c("div", { staticClass: "col" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("orderConfirmation.orderNumber")
                                  )
                                ),
                              ]),
                              _c("span", [
                                _vm._v("#" + _vm._s(_vm.orderNumber(summary))),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      `orderConfirmation.shipping.${_vm.selectedShippingMethod(
                                        summary
                                      )}.title`
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      `orderConfirmation.shipping.${_vm.selectedShippingMethod(
                                        summary
                                      )}.text`
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          summary
                            ? _c("order-confirmation-products", {
                                attrs: { summary: summary },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              summary
                ? _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("div", { staticClass: "storeInfo" }, [
                        _c("h4", [
                          _vm._v(_vm._s(_vm.$t("orderConfirmation.questions"))),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("orderConfirmation.contactUs"))),
                        ]),
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cart.storeFormat", { store: _vm.store })
                            )
                          ),
                        ]),
                        _vm._m(0, true),
                      ]),
                      _c("order-details", {
                        attrs: {
                          "hide-promo-code": true,
                          gst: summary.taxes.gst,
                          qst: summary.taxes.qst,
                          shipping:
                            summary.shippingMethod === 0
                              ? summary.shippingAmount
                              : null,
                          shippingExtra:
                            summary.shipping.extraFee !== undefined &&
                            summary.shipping.extraFee > 0
                              ? summary.shipping.extraFee
                              : null,
                          "sub-total": summary.subTotal,
                          total: summary.total,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "legalnotices",
                              fn: function () {
                                return [_vm._t("legalnotices")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._t("default"),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.summaries.length > 1
                ? _c("hr", { staticClass: "orderConfirmationSeparator" })
                : _vm._e(),
            ])
          }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("span", [
        _c(
          "a",
          {
            staticClass: "mailLink",
            attrs: { href: "mailto:info@laferté.com" },
          },
          [_vm._v("info@laferté.com")]
        ),
      ]),
      _c("span", { staticClass: "phoneLink" }, [_vm._v("819 477-8950")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }