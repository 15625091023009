<template>
  <div class="login">
    <div class="login-emailLogin">
      <validation-observer tag="form"
                           ref="loginForm"
                           class="login-form accountForm"
                           @submit.prevent="submit"
                           novalidate>
        <validation-provider name="email"
                             rules="required|email|max:255"
                             v-slot="{ errors }"
                             slim>
          <div class="formItem">
            <label>{{ $t('users.fields.email') }}</label>
            <input type="email"
                   v-model="email"
                   :class="{ invalid: errors.length }"
                   autocomplete="email" />
            <div class="formItem-errorMessage error"
                 v-if="errors.length">{{ errors[0] }}</div>
          </div>
        </validation-provider>
        <validation-provider name="password"
                             rules="required"
                             v-slot="{ errors }"
                             slim>
          <div class="formItem">
            <label>{{ $t('users.fields.password') }}</label>
            <password-container v-model="password"
                                :errors="errors"></password-container>
            <div class="formItem-errorMessage error"
                 v-if="errors.length">{{ errors[0] }}</div>
            <button type="button"
                    class="btnText resetPassword"
                    @click="handleResetPasswordClick">{{ $t('users.links.forgotPassword') }}</button>
          </div>
        </validation-provider>
        <div class="formItem">
          <working-button class="btn"
                          type="submit"
                          :isWorking="isAuthenticating"
                          :label="submitLabel" />
          <div v-if="!!loginErrorMessage"
               class="error"
               aria-live="polite">{{ loginErrorMessage }}</div>
        </div>
      </validation-observer>
      <div class="privacyPolicyLinkContainer">
        <a :href="privacyPolicyUrl">{{ $t('users.links.privacyPolicy') }}</a>
        <span>&#183;</span>
        <a :href="conditionsUrl">{{ $t('users.links.conditions') }}</a>
      </div>
    </div>
    <hr />
    <div class="login-socialLogins">
      <slot />
      <div class="privacyPolicyLinkContainer">
        <a :href="privacyPolicyUrl">{{ $t('users.links.privacyPolicy') }}</a>
        <span>&#183;</span>
        <a :href="conditionsUrl">{{ $t('users.links.conditions') }}</a>
      </div>
    </div>
    <teleport to='body'>
      <dialog-modal class="wrapper_modal"
                    :isOpen="resetDialogIsOpen"
                    :title="$t('dialogs.titles.resetPassword')"
                    @close:dialog="handleCloseResetDialog">
        <send-password-reset-code v-if="!passwordResetCodeIsValidated"
                                  ref="sendPasswordResetCodeComponent"
                                  @success:validateCode="handleValidateResetCodeSuccess"></send-password-reset-code>
        <reset-password v-else
                        :validated-email="validatedEmail"
                        :validated-activation-code="validatedActivationCode"></reset-password>
      </dialog-modal>
    </teleport>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Teleport from 'vue2-teleport'
import { setFocusOnFirstErrorField } from '../../helpers/domHelper'
import PasswordContainer from '../Forms/PasswordContainer.vue'
import ResetPassword from '../Forms/ResetPassword.vue'
import SendPasswordResetCode from '../Forms/SendPasswordResetCode.vue'
import WorkingButton from '../Forms/WorkingButton.vue'
import DialogModal from '../Utilities/DialogModal.vue'

const httpCodeMap = new Map()
httpCodeMap.set(401, 'InvalidCredentialsException')

export default {
  components: {
    PasswordContainer,
    SendPasswordResetCode,
    Teleport,
    ValidationObserver,
    ValidationProvider,
    DialogModal,
    WorkingButton,
    ResetPassword
  },
  props: {
    conditionsUrl: {
      type: String,
      required: true
    },
    privacyPolicyUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      email: '',
      isAuthenticating: false,
      loginErrorType: undefined,
      password: '',
      passwordResetCodeIsValidated: false,
      resetDialogIsOpen: false,
      showPassword: false,
      validatedEmail: '',
      validatedActivationCode: ''
    }
  },
  methods: {
    handleCloseResetDialog() {
      if (this.$refs.sendPasswordResetCodeComponent) {
        this.$refs.sendPasswordResetCodeComponent.resetForm()
      }
      this.passwordResetCodeIsValidated = false
      this.resetDialogIsOpen = false
    },
    handleResetPasswordClick() {
      this.resetDialogIsOpen = true
    },
    handleValidateResetCodeSuccess({ validatedActivationCode, validatedEmail }) {
      this.validatedActivationCode = validatedActivationCode
      this.validatedEmail = validatedEmail
      this.passwordResetCodeIsValidated = true
    },
    async submit() {
      if (!await this.$refs.loginForm.validate()) {
        setFocusOnFirstErrorField(this.$refs.loginForm.$el, '.invalid')
        return
      }

      try {
        this.loginErrorType = undefined
        this.isAuthenticating = true
        const response = await this.$axios.post('/Umbraco/Api/Authentication/MemberLogin', { email: this.email, password: this.password })
        if (dataLayer) {
          dataLayer = dataLayer || []
          dataLayer.push({
            event: 'login',
            method: 'email',
            user_id: response.data
          })
        }
        if (response.data.defaultBranch && response.data.defaultBranch != this.$root.getStore()) {
          this.$root.selectStore(response.data.defaultBranch)
          this.$root.hasStoreSelected = true
          return
        }
        window.location.reload(true)
      }
      catch (error) {
        this.loginErrorType = httpCodeMap.get(error.response.status)

        if(this.loginErrorType == null) {
          this.loginErrorType = error.response.data.error
        }

        if (!this.loginErrorType) {
          this.loginErrorType = 'LoginException'
        }
      }
      finally {
        this.isAuthenticating = false
      }
    }
  },
  computed: {
    loginErrorMessage() {
      if (!this.loginErrorType) {
        return undefined
      }

      return this.$t(`users.messages.${this.loginErrorType}`)
    },
    submitLabel() {
      if (this.isAuthenticating) {
        return this.$t('users.actions.logingIn')
      }

      return this.$t('users.actions.login')
    }
  },
  provide() {
    return {
      handleValidResetCode: this.handleValidResetCode
    }
  }
}
</script>