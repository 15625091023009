import shippingMethods from '@/models/store/ShippingMethod';

export const addQuantity = (state, product) => {
  let productQuantity = state.cart[product.productId];

  productQuantity = productQuantity
    ? productQuantity + product.quantity
    : product.quantity;

  if (productQuantity > Number.MAX_SAFE_INTEGER) {
    productQuantity = Number.MAX_SAFE_INTEGER;
  }

  state.cart = { ...state.cart, [product.productId]: productQuantity };
};

export const removeProduct = (state, productId) => {
  state.cart = Object.keys(state.cart)
    .filter(key => key !== productId)
    .reduce((cartItem, key) => {
      cartItem[key] = state.cart[key];

      return cartItem;
    }, {});
  state.products = state.products.filter(product => product.Identifier !== productId);
};

export const clearCart = state => {
  state.cart = {};
};

export const clearOrder = state => {
  state.account = undefined;
  state.billingAddress = undefined;
  state.callCustomer = undefined;
  state.customer = undefined;
  state.deliveryDates = undefined;
  state.isProcessingOrder = false;
  state.orderStep = undefined;
  state.pickupContact = undefined;
  state.pickupMyself = undefined;
  state.pickupOptions = undefined;
  state.shippingAddress = undefined;
  state.shippingMethod = shippingMethods.Pickup;
};

export const resetShippingError = (state) => {
  state.shippingError = null;
};

export const setAcceptedTermsConditions = (state, accepted) => {
  state.acceptedTermsConditions = accepted;
};

export const setAddShippingExtra = (state, added) => {
  state.addShippingExtra = added;
};

export const setAccount = (state, account) => {
  state.account = account;
};

export const setActivePromoCode = (state, promoCode) => {
  state.activePromoCode = promoCode;
};

export const setBillingAddress = (state, billingAddress) => {
  state.billingAddress = billingAddress;
};

export const setBusinessAddress = (state, businessAddress) => {
  state.businessAddress = businessAddress;
};

export const setBusinessHours = (state, businessHours) => {
  state.businessHours = businessHours;
};

export const setDeliveryDates = (state, deliveryDates) => {
  state.deliveryDates = deliveryDates;
};

export const setCallCustomerOption = (state, callCustomer) => {
  state.callCustomer = callCustomer;
};

export const setCaptchaToken = (state, token) => {
  state.captchaToken = token;
};

export const setCustomer = (state, customer) => {
  state.customer = customer;
};

export const setDeliveryDate = (state, deliveryDate) => {
  state.deliveryDate = deliveryDate;
};

export const setGlobalPaymentInfo = (state, information) => {
  state.globalPaymentInfo = information;
};

export const setIsUsingBillingAddress = (state, isUsing) => {
  state.useBillingAddress = isUsing;
};

export const setIsProcessingOrder = (state, isProcessing) => {
  state.isProcessingOrder = isProcessing;
};

export const setOrderStep = (state, orderStep) => {
  state.orderStep = orderStep;
};

export const setPaymentMode = (state, paymentMode) => {
  state.paymentMode = paymentMode;
};

export const setPickupContact = (state, contact) => {
  state.pickupContact = contact;
};

export const setPickupMyself = (state, pickupMyself) => {
  state.pickupMyself = pickupMyself;
};

export const setPickupOptions = (state, pickupOptions) => {
  state.pickupOptions = pickupOptions;
};

export const setProducts = (state, products) => {
  state.products = products;
};

export const setShippingPrice = (state, shippingPrice) => {
  state.shippingPrice = shippingPrice;
  state.shippingError = !shippingPrice;
};

export const setQuantity = (state, product) => {
  state.cart = { ...state.cart, [product.productId]: product.quantity };
};

export const setShippingAddress = (state, shippingAddress) => {
  state.shippingAddress = shippingAddress;
};

export const setShippingExtraPrice = (state, price) => {
  state.shippingExtraPrice = price;
};

export const setShippingMethod = (state, shippingMethod) => {
  state.shippingMethod = shippingMethod;
};
