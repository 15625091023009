<template>
  <div class="productResult relatedProduct" :class="{ 'cartPageRelatedProduct': isForCartPage }">
    <div class="productInfosImg">
      <div v-if="isForCartPage" class="header">
        <div :class="['sectionPromo', 'icon' + product.price.tag]" v-if="product.price && product.price.tag && !product.isPriceInStoreOnly">
          <span class="discountValue">
            <span>{{ $root.getDiscount(product.price.discountValue) }}<sup>{{ product.price.discountType === 1 ? '$' : '%' }}</sup></span>
          </span>
        </div>
        <!-- L'ordre est important ici car si le madeIn et le provider sont égaux, c'est le provider qui prévaut-->
        <div class="provider" v-if="product.providerRegion === 2">
          <img src="/images/provider-ca.png" alt="Fournisseur canadien">
        </div>
        <div class="provider" v-else-if="product.providerRegion === 1">
          <img src="/images/provider-qc.png" alt="Fournisseur québécois">
        </div>
        <div class="provider" v-else-if="product.madeInRegion === 2">
          <img src="/images/made-in-canada.png" alt="Fabriqué au Canada">
        </div>
        <div class="provider" v-else-if="product.madeInRegion === 1">
          <img src="/images/made-in-quebec.png" alt="Fabriqué au Québec">
        </div>
      </div>
      <div class="img">
        <a :href="product.url">
          <img v-if="product.images && product.images.length"
               onerror="this.src='/images/image-default.png';"
               :src="product.images[0].url != null ? product.images[0].url : '/images/image-default.png'"
               :alt="product.images[0].alt"
               loading="lazy" />
          <img v-else
               src="/images/image-default.png"
               alt="Image par d�faut"
               loading="lazy" />
        </a>
      </div>
      <div class="productInfos">
        <div v-if="!isForCartPage && product.isWebExclusive != null && product.isWebExclusive == true" class="pill">
          {{ $t('product.pill.webOnly') }}
        </div>
        <div class="name"><a :href="url">{{ product.name }}</a></div>
        <div v-if="isForCartPage" class="sku">#{{ product.sku }}</div>
      </div>
    </div>
  
    <div class="unityPrice">
      <template v-if="!this.$root.getFeatureFlag('hidePrices')">
        <div v-if="product.price != null && product.price.promoSquareFootPrice != null && product.price.squareFootPrice != null" class="price discounted">
          <div class="wDiscount">{{ $root.formatPrice(product.price.promoSquareFootPrice) }}{{ $t('product.squareFootPriceUnit') }}</div>
          <div class="original">{{ $root.formatPrice(product.price.squareFootPrice) }}{{ $t('product.squareFootPriceUnit') }}</div>
        </div>
        <div v-else-if="product.price != null && product.price.squareFootPrice != null" class="price regular">
          <span class="original">{{ $root.formatPrice(product.price.squareFootPrice) }}{{ $t('product.squareFootPriceUnit') }}</span>
        </div>
        <div v-else-if="product.price != null && product.price.discounted" class="price discounted">
          <div class="wDiscount">{{ $root.formatPrice(product.price.discounted) }}</div>
          <div class="original">{{ $root.formatPrice(product.price.value) }}</div>
        </div>
        <div v-else-if="product.price != null" class="price regular">
          <span>{{ $root.formatPrice(product.price.value) }}</span>
        </div>
        <div v-else class="price regular">
          <span>N/D</span>
        </div>
      </template> 
    </div>
    <div v-if="isForCartPage && product.isWebExclusive != null && product.isWebExclusive == true" class="pill">
            {{ $t('product.pill.webOnly') }}
          </div>
    <add-to-cart-indicator :product="product"></add-to-cart-indicator>
  </div>
</template>

<script>
  import AddToCartIndicator from '@/components/Product/AddToCartIndicator.vue'

  export default {
    components: {
      AddToCartIndicator
    },
    props: {
      product: {
        type: Object,
        required: true
      },
      isForCartPage: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      url() {
        return `/${process.env.VUE_APP_PRODUCTS_URL_PREFIX}${this.product.url}`;
      }
    }
  };
</script>
