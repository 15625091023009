var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "carousel" }, [
    _c("div", { staticClass: "carouselContainer" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "categoryName" }, [
          _vm._v(" " + _vm._s(_vm.carousel.sectionTitle) + " "),
        ]),
        (
          _vm.isTwoColumnsDisplayed
            ? _vm.categoryProducts.length > 2
            : _vm.categoryProducts.length > 3
        )
          ? _c("div", { staticClass: "nav" }, [
              _c("div", {
                staticClass: "previousButton",
                class: `previousButton${_vm.sectionTitle}`,
                on: { click: _vm.toPrevious },
              }),
              _c("div", {
                staticClass: "nextButton",
                class: `nextButton${_vm.sectionTitle}`,
                on: { click: _vm.toNext },
              }),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "carouselContent",
          class: `carouselContent${_vm.sectionTitle}`,
        },
        [
          _c(
            "div",
            { staticClass: "productsContainer" },
            _vm._l(_vm.categoryProducts, function (product) {
              return _c("div", { staticClass: "slide" }, [
                _c("div", { staticClass: "slideHeader" }, [
                  product.price &&
                  product.price.tag &&
                  !product.isPriceInStoreOnly
                    ? _c(
                        "div",
                        { class: ["sectionPromo", "icon" + product.price.tag] },
                        [
                          _c("span", { staticClass: "discountValue" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$root.getDiscount(
                                    product.price.discountValue
                                  )
                                )
                              ),
                              _c("sup", [
                                _vm._v(
                                  _vm._s(
                                    product.price.discountType === 1 ? "$" : "%"
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  product.providerRegion === 2
                    ? _c("div", { staticClass: "provider" }, [
                        _c("img", {
                          attrs: {
                            src: "/images/provider-ca.png",
                            alt: "Fournisseur canadien",
                          },
                        }),
                      ])
                    : product.providerRegion === 1
                    ? _c("div", { staticClass: "provider" }, [
                        _c("img", {
                          attrs: {
                            src: "/images/provider-qc.png",
                            alt: "Fournisseur québécois",
                          },
                        }),
                      ])
                    : product.madeInRegion === 2
                    ? _c("div", { staticClass: "provider" }, [
                        _c("img", {
                          attrs: {
                            src: "/images/made-in-canada.png",
                            alt: "Fabriqué au Canada",
                          },
                        }),
                      ])
                    : product.madeInRegion === 1
                    ? _c("div", { staticClass: "provider" }, [
                        _c("img", {
                          attrs: {
                            src: "/images/made-in-quebec.png",
                            alt: "Fabriqué au Québec",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
                _c("img", {
                  staticClass: "slideImage",
                  attrs: { src: _vm.getProductImage(product.sku) },
                }),
                _c("div", { staticClass: "slideData" }, [
                  _vm._v(" " + _vm._s(_vm.getProductName(product.sku)) + " "),
                  _c("div", { staticClass: "slideSku" }, [
                    _vm._v("#" + _vm._s(product.sku)),
                  ]),
                ]),
                _c("a", { staticClass: "btn", attrs: { href: product.url } }, [
                  _vm._v(_vm._s(_vm.$t("marketing.seeProduct"))),
                ]),
              ])
            }),
            0
          ),
        ]
      ),
      _c(
        "a",
        { staticClass: "categoryLink", attrs: { href: _vm.categoryUrl } },
        [_vm._v(_vm._s(_vm.$t("marketing.seeAllProducts")))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }