<template>
  <div class="carousel">
    <div class="carouselContainer">
      <div class="header">
        <div class="categoryName">
          {{ carousel.sectionTitle }}
        </div>
        <div class="nav" v-if="isTwoColumnsDisplayed ? categoryProducts.length > 2 : categoryProducts.length > 3">
          <div class="previousButton" :class="`previousButton${sectionTitle}`" @click="toPrevious"></div>
          <div class="nextButton" :class="`nextButton${sectionTitle}`" @click="toNext"></div>
        </div>
      </div>
      <div class="carouselContent" :class="`carouselContent${sectionTitle}`">
        <div class="productsContainer">
          <div v-for="product in categoryProducts" class="slide">
            <div class="slideHeader">
              <div :class="['sectionPromo', 'icon' + product.price.tag]" v-if="product.price && product.price.tag && !product.isPriceInStoreOnly">
                <span class="discountValue">
                  <span>{{ $root.getDiscount(product.price.discountValue) }}<sup>{{ product.price.discountType === 1 ? '$' : '%' }}</sup></span>
                </span>
              </div>
              <!-- L'ordre est important ici car si le madeIn et le provider sont égaux, c'est le provider qui prévaut-->
              <div class="provider" v-if="product.providerRegion === 2">
                <img src="/images/provider-ca.png" alt="Fournisseur canadien">
              </div>
              <div class="provider" v-else-if="product.providerRegion === 1">
                <img src="/images/provider-qc.png" alt="Fournisseur québécois">
              </div>
              <div class="provider" v-else-if="product.madeInRegion === 2">
                <img src="/images/made-in-canada.png" alt="Fabriqué au Canada">
              </div>
              <div class="provider" v-else-if="product.madeInRegion === 1">
                <img src="/images/made-in-quebec.png" alt="Fabriqué au Québec">
              </div>
            </div>              
              <img :src="getProductImage(product.sku)" class="slideImage" />
              <div class="slideData">
                {{ getProductName(product.sku) }}
                <div class="slideSku">#{{ product.sku }}</div> 
              </div>  
              <a :href="product.url" class="btn">{{ $t('marketing.seeProduct') }}</a>                      
          </div>                   
        </div>
      </div>
      <a :href="categoryUrl" class="categoryLink">{{ $t('marketing.seeAllProducts') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      categoryUrl: '',
      categoryProducts: [],
      sectionTitle: this.carousel.sectionTitle.split(' ').join('').normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace('\'', '').replace(',', '').toLowerCase(),
      windowWidth: window.innerWidth
    }
  },
  props: {
    carousel: {
      type: Object,
      default: null
    },
    products: {
      type: Array,
      default: []
    },
    showRegions: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
    let products = this.products.filter(x => x.omniMajorCategory.toLowerCase() === this.carousel.majorCategoryOmniCode.toLowerCase());

    products = products.filter(x => x.omniIntermediateCategory.toLowerCase() === this.carousel.intermediateCategoryOmniCode.toLowerCase() && !this.carousel.excludedSkus.split(';').includes(x.sku));

    let skuListProducts = products.filter(x => this.carousel.skuList.split(';').includes(x.sku));
    this.categoryProducts = skuListProducts;

    if (this.carousel.skuListOnly === false) {
      let remainingCategoryProducts = products.filter(x => x.omniIntermediateCategory.toLowerCase() === this.carousel.intermediateCategoryOmniCode.toLowerCase() && !this.carousel.skuList.split(';').includes(x.sku));
      if (this.carousel.minorCategoryOmniCode != null && this.carousel.minorCategoryOmniCode !== '') {
        remainingCategoryProducts = remainingCategoryProducts.filter(x => x.omniMinorCategory.toLowerCase() === this.carousel.minorCategoryOmniCode.toLowerCase());
      }
      if (remainingCategoryProducts.length > 10 - skuListProducts.length) {
        remainingCategoryProducts = remainingCategoryProducts.slice(0, 10 - skuListProducts.length);
      }

      this.categoryProducts = skuListProducts.concat(remainingCategoryProducts);
    }

    if (this.carousel.minorCategoryOmniCode != null && this.carousel.minorCategoryOmniCode !== '') {
      this.categoryUrl = `/departements${this.categoryProducts[0].url.substring(0, this.categoryProducts[0].url.lastIndexOf('/'))}#minorCategories=${this.categoryProducts[0].minorCategory}`;
    } else {
      this.categoryUrl = `/departements${this.categoryProducts[0].url.substring(0, this.categoryProducts[0].url.lastIndexOf('/'))}`;
    }
  },
  computed: {
    isNoGap() {
      return this.windowWidth <= 1123;
    },
    isTwoColumnsDisplayed() {
      return this.windowWidth <= 1023 || (this.windowWidth > 1123 && this.windowWidth <= 1700);
    }
  },
  methods: {
    getProductImage(sku) {
      var imageUrl = '/images/image-default.png';
      var product = this.products.filter(x => x.sku === sku)[0];
      if (product != null && product.images != null && product.images[0].url != null) {
        imageUrl = product.images[0].url;
      }
      return imageUrl;
    },
    getProductName(sku) {
      var productName = '';
      var product = this.products.filter(x => x.sku === sku)[0];
      if (product != null) {
        productName = product.name;
      }
      return productName;
    },
    toNext() {
      const carouselContent = document.querySelector(`.carouselContent${this.sectionTitle}`);
      const contentGap = this.isNoGap ? 0 : parseInt(window.getComputedStyle(carouselContent, null).getPropertyValue('gap').replace('px', ''));
      const slidewidth = document.querySelector('.slide').offsetWidth;
      const step = slidewidth + contentGap;
      const maxDisplayedElements = this.isTwoColumnsDisplayed ? 2 : 3;
      const lastElementPosition = (step * (this.categoryProducts.length - maxDisplayedElements)) - 30;
      if (carouselContent.scrollLeft >= lastElementPosition) {        
        carouselContent.scrollLeft -= carouselContent.scrollWidth;
      } else {
        carouselContent.scrollLeft += step;
      }      
    },
    toPrevious() {
      const carouselContent = document.querySelector(`.carouselContent${this.sectionTitle}`);
      const contentGap = this.isNoGap ? 0 : parseInt(window.getComputedStyle(carouselContent, null).getPropertyValue('gap').replace('px', ''));
      const slidewidth = document.querySelector('.slide').offsetWidth;
      const step = slidewidth + contentGap;
      if (carouselContent.scrollLeft === 0) {        
        carouselContent.scrollLeft += 5000;
      } else {
        carouselContent.scrollLeft -= step;
      }      
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  }
}
</script>