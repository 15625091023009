<template>
  <div class="orderDetailsSection">
    <div class="bgWhite">
      <div class="orderDetails">
        <div class="title">
          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
          </svg>
          {{ title || $t('cart.title') }}
        </div>
        <div class="cartTable">
          <template v-if="!this.$root.getFeatureFlag('hidePrices')">
            <div class="bottom">
              <div class="cartRow">
                <div>{{ $t('cart.subTotal') }}</div>
                <div>
                  <!-- <span>{{ subTotal !== null ? $root.formatPrice(subTotal - (shipping || 0)) : '--' }}</span> -->
                  <span v-if="subTotal !== null && shipping === null">{{ $root.formatPrice(subTotal) }}</span>
                  <span v-if="subTotal !== null && shipping !== null && shippingExtra !== null">{{ $root.formatPrice(subTotal - shipping - shippingExtra) }}</span>
                  <span v-else-if="subTotal !== null && shipping !== null">{{ $root.formatPrice(subTotal - shipping) }}</span>                  
                  <span v-if="subTotal === null && shipping === null">--</span>
                </div>
              </div>
              <div class="cartRow" v-if="shipping !== null">
                <div>{{ $t('cart.shipping') }}</div>
                <div>
                  <span>{{ $root.formatPrice(shipping) }}</span>
                </div>
              </div>
              <div class="cartRow" v-if="shippingExtra !== null && shippingExtra > 0">
                <div>{{ $t('cart.shippingExtra') }}</div>
                <div>
                  <span>{{ $root.formatPrice(shippingExtra) }}</span>
                </div>
              </div>
              <hr>
              <div class="cartRow">
                <div>{{ $t('cart.taxes') }}</div>
                <div>
                  <span v-if="gst !== null">
                    {{ $t('cart.gst') }} {{ $root.formatPrice(gst) }}
                  </span>
                  <span v-else>--</span>
                </div>
              </div>
              <div class="cartRow">
                <div></div>
                <div>
                  <span v-if="qst !== null">
                    {{ $t('cart.qst') }} {{ $root.formatPrice(qst) }}
                  </span>
                  <span v-else>--</span>
                </div>
              </div>
              <hr>
              <div class="cartRow orderTotal">
                <div class="text">{{ $t('cart.orderTotal') }} :</div>
                <div class="price">
                  <span v-if="total !== null">{{ $root.formatPrice(total) }}</span>
                  <span v-else>--</span>
                </div>
              </div>
              <div :class="{ 'promocode-container': true, 'opened': isPromocodeOpened }" v-if="showPromoCode">
                <div class="promocode-header" @click="isPromocodeOpened = !isPromocodeOpened">
                  <span>{{ $t('promocode.applyAPromocode') }}</span>
                  <span class="icon"></span>
                </div>
                <div class="promocode-body" v-show="isPromocodeOpened">
                  <div class="formField">
                    <label>{{ $t('promocode.enterCode') }}</label>
                    <input type="text" v-model="promoCode" @keydown.enter="applyPromocode">
                  </div>
                  <div v-if="promoCodeError != null" class="formItem-errorMessage error">{{ promoCodeError }}</div>
                  <div v-if="promoCodeMessage != null" class="formItem-successMessage success">{{ promoCodeMessage }}</div>
                  <button class="btn" @click="applyPromocode">{{ $t('promocode.apply') }}</button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <slot name="bottom" />
      <slot name="legalnotices" />
    </div>
    <div v-if="hasRegularAndBarcodeItems" class="billSplitMentionning">
      <img src="/images/info-icon.png">
      <p class="firstLine">{{ $t('product.billSplitMention') }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { TheMask } from 'vue-the-mask';

export default {
  components: {
    TheMask
  },
  props: {
    gst: {
      validator: prop => ['number', 'string'].includes(typeof prop) || prop === null,
      required: true
    },
    hasRegularAndBarcodeItems: {
      type: Boolean,
      default: false
    },
    hidePromoCode: {
      type: Boolean,
      default: false
    },
    promoCodeConfiguration: {
      type: Object,
      default: null,
    },
    qst: {
      validator: prop => ['number', 'string'].includes(typeof prop) || prop === null,
      required: true
    },
    shipping: {
      validator: prop => ['number', 'string'].includes(typeof prop) || prop === null,
      required: false
    },
    shippingExtra: {
      validator: prop => ['number', 'string'].includes(typeof prop) || prop === null,
      required: false
    },
    subTotal: {
      validator: prop => typeof prop === 'number' || prop === null,
      required: true
    },
    title: {
      type: String
    },
    total: {
      validator: prop => typeof prop === 'number' || prop === null,
      required: true
    }
  },
  data() {
    return {
      isPromocodeOpened: false,
      promoCode: '',
      promoCodeError: null,
      promoCodeMessage: null
    }
  },
  created() {
    if (this.activePromoCode != null) {
      this.isPromocodeOpened = true;
      this.promoCode = this.activePromoCode.promoCode;
      this.promoCodeMessage = this.$t('promocode.valid', { percent: this.promoCodeConfiguration.percentageValue});
    }
  },
  methods: {
    ...mapActions('cartModule', ['setActivePromoCode']),
    applyPromocode() {
      this.setActivePromoCode(null);
      this.promoCodeError = null;
      this.promoCodeMessage = null;

      if (this.promoCode !== this.promoCodeConfiguration.promoCode) {
        this.promoCodeError = this.$t('promocode.invalid');
        return;
      }

      const now = new Date();
      const start = new Date(this.promoCodeConfiguration.startDate);
      const end = new Date(this.promoCodeConfiguration.endDate);

      if (now.getTime() < start.getTime() || now.getTime() > end.getTime()) {
        this.promoCodeError = this.$t('promocode.invalid');
        return;
      }

      this.promoCodeMessage = this.$t('promocode.valid', { percent: this.promoCodeConfiguration.percentageValue});
      this.setActivePromoCode(this.promoCodeConfiguration);
    }
  },
  computed: {
    ...mapState('cartModule', ['activePromoCode']),
    showPromoCode() {
      return !this.hidePromoCode && window.laferte.featureFlags.promoCodeActive;
    }
  },
  watch: {
    activePromoCode(value) {
      if (value) {
        this.promoCode = value.promoCode;
      }
    }
  }
};
</script>
