import productStatus from '@/models/product/Status';
import InternalStatus from '@/models/product/InternalStatus';
import { relatedProducts } from './store/cartModule/getters';

export default {
  methods: {
    getPromotionEndDate(product) {
      const price = product.price ? product.price : null;

      if (!price) {
        return null;
      }

      const date = price.endDate != null ? new Date(price.endDate) : null;

      if (date === null) {
        return null;
      }

      return this.$t('cart.promotionExpiration', {
        date: new Intl.DateTimeFormat(
          'fr-CA',
          {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }).format(date)
      });
    },
    getQuantity(product) {
      if (!product || !product.quantities) {
        return null;
      }
      return product.quantities.find(x => x.store === this.$root.getStore());
    },
    getQuantityValue(product) {
      const quantity = this.getQuantity(product);
      return quantity ? quantity.value : null;
    },
    isBarcodeItem(product) {
      return product && product.isBarcodeItem;
    },
    isInactive(product) {
      return product && product.status === productStatus.Inactive;
    },
    isInStock (product)  {
      const quantity = this.getQuantity(product);
      const qytInStock = quantity ? quantity.inStock : false;
      return this.isBarcodeItem(product) || qytInStock;
    },
    isPriceInStoreOnly(product) {
      return product && product.isPriceInStoreOnly;
    },
    isPromotional(product) {
      if(!product || !product.internalStatuses) {
        return false;
      }
      const internalStatus = product.internalStatuses.find(x => x.store === this.$root.getStore());

      return internalStatus.value === InternalStatus.Promotional;
    },
    isRecall(product) {
      if(!product || !product.internalStatuses) {
        return false;
      }
      const internalStatus = product.internalStatuses.find(x => x.store === this.$root.getStore());

      return internalStatus.value === InternalStatus.StoppedForBilling;
    },
    isUnavailable(product) {
      return this.isInactive(product)
        || !this.isInStock(product)
        || this.isPriceInStoreOnly(product)
        || this.isPromotional(product)
        || this.isRecall(product);
    },
    isWebExclusive(product) {
      return product && product.isWebExclusive;
    },
    async setRelatedProducts(products) { // pour obtenir les produits connexes de ceux dans le cart
      const priceDescOrderedProducts = products.slice().sort((a, b) => b.price.bestprice < a.price.bestPrice) // on doit prioriser les relatedProducts des produits les plus chers
      let relatedProductSkus = priceDescOrderedProducts.flatMap(product => product.relatedProductSkus && product.relatedProductSkus.length > 0 ? product.relatedProductSkus : [])
      relatedProductSkus = relatedProductSkus.filter((n, i) => n.length > 0 && relatedProductSkus.indexOf(n) === i)

      const currentSkus = this.relatedProducts.map(x => x.sku)

      if (currentSkus.length === relatedProductSkus.length) {
        return
      }
    
      const sortedCurrent = currentSkus.slice().sort()
      const sortedNext = relatedProductSkus.slice().sort()
    
      const isEqual = sortedCurrent.length > 0 && sortedCurrent.every((value, index) => {
        return value === sortedNext[index]
      })
      

      if (isEqual) {
        return
      }

      const productSkus = priceDescOrderedProducts.map(x => x.sku)
      relatedProductSkus = relatedProductSkus.filter(x => !productSkus.includes(x)).slice(0, 4)

      if (relatedProductSkus != null || relatedProductSkus.length > 0) {
        await this.$store.dispatch('cartModule/setRelatedProducts', relatedProductSkus)
      }
    }
  }
};
