import productMixins from '@/productMixins';
import { mapGetters, mapState } from 'vuex';
import { pipe } from '@/functions';
import moment from 'moment';

export default {
  computed: {
    ...mapGetters('cartModule', ['addShippingExtra', 'cart', 'products', 'isDelivery', 'shippingExtraPrice', 'shippingPrice']),
    ...mapState('cartModule', ['activePromoCode']),
    gst() {
      return Math.round(process.env.VUE_APP_GST_TAX * this.subTotal() + 'e+2') + 'e-2';
    },
    qst() {
      return Math.round(process.env.VUE_APP_QST_TAX * this.subTotal() + 'e+2') + 'e-2';
    }
  },
  methods: {
    addSubTotal(amount) {
      return amount += this.subTotal();
    },
    applyGst(cutShippingPrices) {
      var currentUtcTime = moment().utc();
      var startDateUtcTime = moment.utc(this.tpsExemptedProductsConfiguration.startDate);
      var endDateUtcTime = moment.utc(this.tpsExemptedProductsConfiguration.endDate);
      let mustcheckExemptTpsProducts = false;
      if (this.isTpsExemptionEnabled && this.tpsExemptedProductsConfiguration != null && currentUtcTime < endDateUtcTime && currentUtcTime > startDateUtcTime) {
        mustcheckExemptTpsProducts = true;
      }
      return Number(Math.round(process.env.VUE_APP_GST_TAX * this.subTotal(cutShippingPrices, mustcheckExemptTpsProducts) + 'e+2') + 'e-2');
    },
    applyQst(cutShippingPrices) {
      return Number(Math.round(process.env.VUE_APP_QST_TAX * this.subTotal(cutShippingPrices) + 'e+2') + 'e-2');
    },
    deliveryExtraFee(){
      if (this.addShippingExtra) {
        return this.shippingExtraPrice;
      }
      return 0;
    },
    deliveryFee(){
      if (this.isDelivery) {
        return this.shippingPrice;
      }
      return 0;
    },
    applyQuantity(product) {
      if (product === null) {
        return 0;
      }
      return product.basePrice * this.getProductQuantity(product.product);
    },
    applyTaxes(amount) {
      const taxes = [
        process.env.VUE_APP_GST_TAX,
        process.env.VUE_APP_QST_TAX
      ];
      let taxAmount = 0;
      taxes.forEach(tax => taxAmount += + (Math.round(tax * amount + 'e+2') + 'e-2'));
      return taxAmount;
    },
    getBasePrice(product) {
      const price = product.price
        ? product.price
        : null

      if (!price) {
        return null
      }

      const basePrice = price.discounted !== null 
        ? price.discounted 
        : this.activePromoCode != null && this.isProductElligibleToPromoCode(product)
          ? price.value - Math.round((this.activePromoCode.percentageValue / 100) * price.value * 100) / 100
          : price.value

      return {
        basePrice,
        product: product,
        promotionEndDate: price.endDate !== null ? price.endDate : null
      };
    },
    getProductQuantity(product) {
      return this.isUnavailable(product) ? 0 : this.cart[product.identifier];
    },
    hasRegularAndBarcodeItems(products) {
      return products.some(x => x.isBarcodeItem) && products.some(x => !x.isBarcodeItem)
    },
    isProductElligibleToPromoCode(product) {
      if (!this.activePromoCode) {
        return false
      }

      const internalStatus = product.internalStatuses.find(x => x.store === this.$root.getStore())

      const isProductElligible = !this.activePromoCode.omniCodeToExclude.includes(product.omniMajorCategory)
        && !this.activePromoCode.omniStatusToExclude.includes(internalStatus.value)

      return isProductElligible
    },
    subTotal(cutShippingPrices, mustcheckExemptTpsProducts) {
      if (!this.$root.hasStoreSelected) {
        return null;
      }

      const getProductSubTotal = pipe(this.getBasePrice, this.applyQuantity);
      let total = 0;
      if (mustcheckExemptTpsProducts) {
        total = this.products.filter(x => !this.tpsExemptedProductsConfiguration.skus.includes(x.sku)).reduce((subTotal, product) => subTotal + getProductSubTotal(product), 0);
      } else {
          total = this.products.reduce((subTotal, product) => subTotal + getProductSubTotal(product), 0);
      }


      if (cutShippingPrices !== true) {
        total += this.deliveryExtraFee();
        total += this.deliveryFee();
      }

      return total;
    },
    taxes() {
      return pipe(this.applyTaxes)(this.subTotal());
    },
    total() {
      if (this.isTpsExemptionEnabled) {
        let total = 0;
        total = pipe(this.applyGst)(this.subTotal());
        total += pipe(this.applyQst, this.addSubTotal)(this.subTotal());
        return total;
      }
        return pipe(this.applyTaxes, this.addSubTotal)(this.subTotal());
    }
  },
  mixins: [productMixins]
};
