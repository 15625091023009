<template>
  <validation-observer ref="observer" v-slot="{ invalid }" slim>
    <div id="orderCustomer" class="orderCustomer box">
      <div class="orderTitle">
        <h4>
          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
          </svg>
          {{ $t('order.customer.title') }}
        </h4>
        <div class="requiredFieldLabel">{{$t('order.requiredField')}}</div>
      </div>
      <form @submit.prevent="submit">
        <div class="row">
          <validation-provider name="firstName" rules="required|max:50" v-slot="{ errors, touched }" slim>
            <div class="form-group">
              <label>{{ $t('order.customer.firstName') }}*</label>
              <input type="text" v-model.lazy="customer.firstName" :class="{ invalid: touched && errors.length }" autocomplete="shipping given-name" />
            </div>
          </validation-provider>
          <validation-provider name="lastName" rules="required|max:50" v-slot="{ errors, touched }" slim>
            <div class="form-group">
              <label>{{ $t('order.customer.lastName') }}*</label>
              <input type="text" v-model.lazy="customer.lastName" :class="{ invalid: touched && errors.length }" autocomplete="shipping family-name" />
            </div>
          </validation-provider>
        </div>
        <order-billing :addressCompleteSettings="addressCompleteSettings" @addressUpdated="$nextTick(() => $refs.observer.validate())" :user="user" />
        <div class="row">
          <validation-provider name="email" rules="required|email|max:255" v-slot="{ errors, touched }" slim>
            <div class="formItem">
              <label>{{ $t('order.customer.email') }}*</label>
              <input type="email" v-model.lazy="customer.email" :class="{ invalid: touched && errors.length }" autocomplete="shipping email" />
            </div>
          </validation-provider>
          <validation-provider name="emailConfirmation" rules="required|email|max:255|emailMatch" v-slot="{ errors, touched }" slim>
            <div class="form-group">
              <label>{{ $t('order.customer.emailConfirmation') }}*</label>
              <input type="email" v-model.lazy="customer.emailConfirmation" :class="{ invalid: touched && errors.length }" autocomplete="shipping email-confirmation" />
            </div>
          </validation-provider>
        </div>
        <div class="row">
          <validation-provider name="phone" rules="required|phone|max:20" v-slot="{ errors }" slim>
            <div class="form-group">
              <label>{{ $t('order.customer.phone') }}*</label>
              <the-mask type="tel" mask="(###) ###-####" v-model.lazy="customer.phone" @input="$nextTick(() => $refs.observer.validate())" @blur.native="setPhoneNumberTouched" :class="{ invalid: isPhoneNumberTouched && errors.length > 0 }" />
            </div>
          </validation-provider>
          <div></div>
        </div>
        <div class="row checkbox">
          <div class="checkboxContainer" @click="customer.subscribeNewsletter = !customer.subscribeNewsletter">
            <input type="checkbox" v-model="customer.subscribeNewsletter" />
            <span class="checkmark"></span>
            <label>{{ $t('order.customer.subscribeNewsletter') }}</label>
          </div>
        </div>
        <div class="row checkbox">
          <validation-provider name="pickupfirstName" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }" slim>
            <div class="terms">
              <label class="checkboxContainer">
                <input type="checkbox" v-model="acceptedTermsConditions" :class="{ invalid: errors.length }">
                <span class="checkmark" />
                <a href="/mentions-legales" target="_blank">{{ $t('order.payment.termsConditions') }}</a>
              </label>
            </div>
          </validation-provider>
        </div>
        <div class="orderTitle row">
          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
          </svg>
          {{ $t('order.shipping.title') }}
        </div>
        <div v-if="hasRegularAndBarcodeItems(products)" class="billSplitMentionning">
          <img src="/images/info-icon.png">
          <p class="firstLine">{{ $t('product.billSplitMention') }}</p>
        </div>
        <div class="orderShipping">
          <div class="shippingSelector row">
            <div class="radioOptions">
              <div>
                <div class="checkboxContainer" @click="setShipping(shippingMethods.Pickup)">
                  <input type="radio" id="pickup" name="selectedShippingMethod" :value="shippingMethods.Pickup" v-model="selectedShippingMethod">
                  <span class="radio"></span>
                  <label>
                    <b>{{ $t('cart.shippingMethod.pickup') }}</b>
                    <span>{{ $t('cart.shippingMethod.pickupDescription', { fromHours: 3 }) }} : <span>{{ $t('cart.shippingMethod.free') }}</span></span>
                  </label>
                </div>
              </div>
              <div>
                <div :class="{ checkboxContainer: true, disabled: shippingDisabled }" @click="setShipping(shippingMethods.Delivery)">
                  <input type="radio" id="shipping" name="selectedShippingMethod" :value="shippingMethods.Delivery" v-model="selectedShippingMethod" :disabled="shippingDisabled">
                  <span class="radio"></span>
                  <label>
                    <b>{{ $t('cart.shippingMethod.delivery') }}</b>
                    <span>{{ $t('cart.shippingMethod.deliveryDescription', { fromDays: 1, toDays: 7 }) }}<span v-if="showShippingPriceExtraLabel"> : {{ $root.formatPrice(shippingPrice) }}</span></span>
                    <span class="extraLabel">{{ $t('cart.shippingMethod.deliveryDescriptionHours', { fromHours: "8h00", toHours: "17h00" }) }}</span>
                    <span class="extraLabel">{{ $t('cart.shippingMethod.deliveryDescriptionMaxDistance', { maxDistanceInKm: 110 }) }}</span>
                    <small class="distanceTooHigh" v-if="isDistanceTooHigh">{{ $t("cart.shippingErrors.distanceTooHigh") }}</small>
                  </label>
                </div>
              </div>
              <div>
                <div v-if="shippingMethod === shippingMethods.Delivery" :class="{checkboxContainer: true}">
                  <input type="checkbox" id="shippingExtra" :checked="addShippingExtra" :value="addShippingExtra" @click="setAddShippingExtra(!addShippingExtra)">
                  <span class="checkmark" @click="setAddShippingExtra(!addShippingExtra)"></span>
                  <label for="shippingExtra">
                    <b>{{ $t('cart.shippingExtraMessage') }}</b>
                    <span class="extraLabel">{{ $t('cart.shippingExtraConditions') }}</span>
                    <span class="extraLabel">{{ $t('cart.shippingExtraFee', { fee: shippingExtraPrice }) }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <order-pickup v-if="shippingMethod === shippingMethods.Pickup" />
          <delivery-date-selection v-if="shippingMethod === shippingMethods.Delivery" />
          <div v-if="shippingMethod === shippingMethods.Delivery">
            <p>
              <strong>{{ $t('order.payment.ShippingAddress') }}</strong>
            </p>
            <shipping-address :addressCompleteSettings="addressCompleteSettings" @addressUpdated="$nextTick(() => $refs.observer.validate())" :user="user" />
          </div>
        </div>
        <div class="row" v-if="shippingMethod === shippingMethods.Pickup">
          <div class="checkboxContainer" @click="pickupMyself = !pickupMyself">
            <input type="checkbox" v-model="notPickupMyself" />
            <span class="checkmark"></span>
            <label>{{ $t('order.customer.pickupMyself') }}</label>
          </div>
        </div>
        <div class="moreInfos" v-if="shippingMethod === shippingMethods.Pickup && notPickupMyself">
          <h4>{{ $t('order.customer.pickupNameLabel') }}</h4>
          <div class="row">
            <validation-provider name="pickupfirstName" rules="required|max:50" v-slot="{ errors }" slim>
              <div class="form-group">
                <label>{{ $t('order.customer.firstName') }}*</label>
                <input type="text" v-model.lazy="pickupContact.firstName" :class="{ invalid: errors.length }" autocomplete="shipping given-name" />
              </div>
            </validation-provider>
            <validation-provider name="pickupLastName" rules="required|max:50" v-slot="{ errors }" slim>
              <div class="form-group">
                <label>{{ $t('order.customer.lastName') }}*</label>
                <input type="text" v-model.lazy="pickupContact.lastName" :class="{ invalid: errors.length }" autocomplete="shipping family-name" />
              </div>
            </validation-provider>
          </div>
          <div class="row">
            <validation-provider name="pickupEmail" rules="required|max:255" v-slot="{ errors }" slim>
              <div class="form-group">
                <label>{{ $t('order.customer.email') }}*</label>
                <input type="email" v-model.lazy="pickupContact.email" :class="{ invalid: errors.length }" autocomplete="shipping email" />
              </div>
            </validation-provider>
            <validation-provider name="pickupEmailConfirmation" rules="required|max:255|pickupEmailMatch" v-slot="{ errors }" slim>
              <div class="form-group">
                <label>{{ $t('order.customer.emailConfirmation') }}*</label>
                <input type="email" v-model.lazy="pickupContact.emailConfirmation" :class="{ invalid: errors.length }" autocomplete="shipping email-confirmation" />
              </div>
            </validation-provider>
          </div>
        </div>
        <order-details class="m" :gst="gst"
                       :promo-code-configuration="promoCodeConfiguration"
                       :qst="qst"
                       :shipping="shippingMethod === shippingMethods.Delivery ? shippingPrice : null"
                       :sub-total="subTotal()"
                       :total="total()">
          <template v-slot:legalnotices>
            <slot name="legalnotices" />
          </template>
        </order-details>
        <button type="submit" class="btn" :disabled="invalid || isSubmitButtonDisabled" v-if="orderStep < OrderStep.Payment">{{ $t('order.customer.submit') }}</button>
      </form>
    </div>
  </validation-observer>
</template>

<script>
import DeliveryDateSelection from './DeliveryDateSelection.vue'
import OrderBilling from './OrderBilling.vue'
import OrderDetails from '@/components/Cart/OrderDetails'
import orderMixins from '@/orderMixins'
import OrderPickup from './OrderPickup.vue'
import OrderStep from '@/models/cart/OrderStep'
import ShippingAddress from './ShippingAddress.vue'
import shippingMethods from '@/models/store/ShippingMethod'
import { mapState, mapActions } from 'vuex'
import { TheMask } from 'vue-the-mask'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'

export default {
  props: {
    addressCompleteSettings: {
      type: Object,
      required: true
    },
    promoCodeConfiguration: {
      type: Object,
      default: null,
    },
    shippingDisabled: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      default: null
    }
  },
  components: {
    DeliveryDateSelection,
    OrderBilling,
    OrderDetails,
    OrderPickup,
    OrderStep,
    ShippingAddress,
    TheMask,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      acceptedTermsConditions: false,
      customer: {
        email: '',
        emailConfirmation: '',
        firstName: '',
        lastName: '',
        phone: '',
        subscribeNewsletter: false
      },
      isLoaded: false,
      isPhoneNumberTouched: false,
      OrderStep,
      pickupContact: {
        email: '',
        emailConfirmation: '',
        firstName: '',
        lastName: ''
      },
      pickupMyself: false,
      selectedShippingMethod: null,
      shippingMethods
    }
  },
  computed: {
    ...mapState('cartModule', ['addShippingExtra', 'orderStep', 'shippingError', 'shippingMethod', 'shippingExtraPrice', 'shippingPrice']),
    isContactEmailMatching() {
      return this.customer.email === this.customer.emailConfirmation
    },
    isCartEmpty() {
      const productIds = Object.keys(this.cart);
      return productIds.length === 0;
    },
    isDistanceTooHigh() {
      return this.shippingPrice === null
    },
    isPickupContactEmailMatching() {
      return this.pickupMyself || this.pickupContact.email === this.pickupContact.emailConfirmation
    },
    isSubmitButtonDisabled() {
      return !this.isContactEmailMatching || !this.isPickupContactEmailMatching || this.isShippingDisabled || this.isCartEmpty
    },
    isShippingDisabled() {
      return this.selectedShippingMethod === shippingMethods.Delivery && (this.shippingError || this.shippingPrice == null)
    },
    notPickupMyself() {
      return !this.pickupMyself
    },
    showShippingPriceExtraLabel() {
      return this.selectedShippingMethod === shippingMethods.Delivery && this.shippingPrice > 0
    }
  },
  methods: {
    ...mapActions('cartModule', [
      'setAcceptedTermsConditions',
      'setAddShippingExtra',
      'setBillingAddress',
      'setCustomer',
      'setOrderStep',
      'setPickupContact',
      'setPickupMyself',
      'setShippingMethod']),
      setPhoneNumberTouched() {
        this.isPhoneNumberTouched = true
      },
    setShipping(shippingMethod) {
      if (shippingMethod === shippingMethods.Delivery && this.shippingDisabled) {
        return
      }

      this.selectedShippingMethod = shippingMethod
      if (shippingMethod === shippingMethods.Pickup) {
        this.setAddShippingExtra(false)
      }
    },
    sendAddShippingInfoGaEvent() {
      try {
        if (dataLayer) {
          dataLayer = dataLayer || []
          dataLayer.push({
            event: 'add_shipping_info', 
            ecommerce: {
              shipping_tier: this.selectedShippingMethod === shippingMethods.Delivery ? 'Livraison' : 'Ramassage',
              items: this.products.map(item => ({
                item_name: item.name,
                item_id: item.sku,
                item_category: item.majorCategory,
                item_category2: item.intermediateCategory,
                item_category3: item.minorCategory
              }))
            }
          })
        }
      } catch { /*Fait rien*/ }
    },
    submit() {
      if (this.orderStep === OrderStep.Customer) {
        this.setOrderStep(OrderStep.Payment)
        this.sendAddShippingInfoGaEvent()
      }

      const orderPayment = document.getElementById('orderPayment')

      if (orderPayment) {
        orderPayment.scrollIntoView()
      }

    }
  },
  async created() {
    this.setShipping(this.shippingMethod)
    const storedCustomer = this.$store.state.cartModule.customer
    if (storedCustomer) {
      this.customer.email = storedCustomer.email
      this.customer.emailConfirmation = storedCustomer.emailConfirmation
      this.customer.firstName = storedCustomer.firstName
      this.customer.lastName = storedCustomer.lastName
      this.customer.phone = storedCustomer.phone
      this.customer.subscribeNewsletter = Boolean(storedCustomer.subscribeNewsletter)
    }

    const storedPickupMyself = this.$store.state.cartModule.pickupMyself
    if (typeof storedPickupMyself !== 'undefined') {
      this.pickupMyself = storedPickupMyself
    }

    const storedPickupContact = this.$store.state.cartModule.pickupContact
    if (storedPickupContact) {
      this.pickupContact.email = storedPickupContact.email
      this.pickupContact.emailConfirmation = storedCustomer.emailConfirmation
      this.pickupContact.firstName = storedPickupContact.firstName
      this.pickupContact.lastName = storedPickupContact.lastName
    }

    this.$nextTick(() => this.$refs.observer.validate()); 

    this.isLoaded = true
  },
  updated() {
    const storedCustomer = this.$store.state.cartModule.customer
    
    if (this.user) {
      this.customer.email = storedCustomer.email ? storedCustomer.email : this.user.contact.email
      this.customer.firstName = storedCustomer.firstName ? storedCustomer.firstName : this.user.contact.firstName
      this.customer.lastName = storedCustomer.lastName ? storedCustomer.lastName : this.user.contact.lastName
      this.customer.phone = storedCustomer.phone ? storedCustomer.phone : this.user.contact.phone
    }
  },
  watch: {
    acceptedTermsConditions(value) {
      this.setAcceptedTermsConditions(value)
    },
    customer: {
      deep: true,
      handler(value) {
        this.setCustomer(value)
        if (this.isLoaded && !this.notPickupMyself) {
          this.pickupContact = {
            email: value.email,
            firstName: value.firstName,
            lastName: value.lastName
          }
        }
      }
    },
    pickupContact: {
      deep: true,
      handler(value) {
        this.setPickupContact(value)
      }
    },
    pickupMyself: {
      handler(value, old) {
        if (this.isLoaded && value !== old) {
          if (value === false) {
            this.pickupContact = { email: '', emailConfirmation: '', firstName: '', lastName: '' }
          } else if (value === true) {
            this.pickupContact = {
              email: this.customer.email,
              emailConfirmation: this.customer.emailConfirmation,
              firstName: this.customer.firstName,
              lastName: this.customer.lastName
            }
          }
        }
        this.setPickupMyself(value)
      }
    },
    selectedShippingMethod(value) {
      this.setShippingMethod(value)
    }
  },
  mixins: [orderMixins],
  mounted() {
    extend('emailMatch', {
      validate: (value) => value === this.customer.email
    })
    extend('pickupEmailMatch', {
      validate: (value) => value === this.pickupContact.email
    })
  }
}
</script>
