export function setErrorModal({ commit }, value) {
  return commit('setErrorModal', value === true ? 'errorModal.text' : value);
}

export function setHasPromoCodePopupBeenSeen({ commit }, value) {
  return commit('setHasPromoCodePopupBeenSeen', value);
}

export function setStoreChangeWarningModal({ commit }, data) {
  return commit('setStoreChangeWarningModal', data);
}

export function setSelectedStoreInformation({ commit }, data) {
  return commit('setSelectedStoreInformation', data);
}
